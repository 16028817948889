const setCollectionsList = (data) => ({
  type: "SET_COLLECTIONS_LIST",
  data,
});

const setActiveCollections = (data) => ({
  type: "SET_ACTIVE_COLLECTIONS",
  data,
});

const setSelectedCollectionId = (data) => {
  console.log("here");
  return {
    type: "SET_SELECTED_COLLECTION_ID",
    data,
  };
};
const setCollectionsResponse = (data) =>({
  type: "SET_COLLECTION_RES",
  data,
})
const setCollectionsLoading = (data) => ({
  type: "SET_COLLECTION_LOADING",
  data,
});
export const mutation = {
  setCollectionsList,
  setActiveCollections,
  setSelectedCollectionId,
  setCollectionsResponse,
  setCollectionsLoading,
};
