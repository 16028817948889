import React, { Component } from "react";
import { Button, Form, Input, Select, TreeSelect } from "antd";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import OtpInput from "react-otp-input";

import * as userActions from "store/user/actions";
import LoginImg from "assets/imagev2/freadom_login.svg";
import { storage as LocalStorage } from "services/config/storage";
import store from "../../store";
import CloseBlackIcon from "assets/image/close-black.svg";
import * as EVENT from "../../utils/eventKeys";
import loader from "../../assets/image/loader.gif";
import "../AddProfile/addProfile.scss";
import grades from "./constants";
import { Analytics } from "../../services/analytics";
import { formatDate } from "../../utils/commonUtils";
import version from "../../../package.json";
import { v4 as uuidv4 } from "uuid";
import mixpanel from "mixpanel-browser";

const uuid = uuidv4()
const { Option } = Select;
const otpBoxStyle = {
  // width: '100%',
  width: "45px",
  textAlign: "center",
  height: "45px",
  outline: "none",
  fontSize: "24px",
  border: "1px solid rgb(238, 238, 238)",
  borderRadius: "10px",
};
class Addprofile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      child: null,
      error: null,
      is_teacher: false,
      childState: "",
      enteredName: false,
      treeGrades: null,
      statesData: this.getStatesAndUnionTerritories(),
      selectedSections: [],
      gradeSections: [],
      childSection: null,
      gradeSelected: false,
      hasErrors: false,
      enteredName: false,
      parentData: LocalStorage.fetch.parentData(),
      code: null,
      verifingSchool: false,
      schoolCode: null,
      gradeList: grades,
      loading: false,
      defaultChild: LocalStorage.fetch.defaultChild(),
      switchingChild: false,
      gradeSelectionValid: false,
      SchoolCodeSelected: true,
      schoolLanguages: [],
    };
  }

  componentDidMount = () => {
    // console.log("this.state:", this.state);
    this.props.getGrades();
    // // console.log(this.props);
    // console.log("parent:", this.state.parentData);
    const school_code = this.state.defaultChild.school_code;
    // console.log("school code is :", school_code);
    this.setState({
      is_teacher: this.state.parentData.is_teacher,
      code: this.state.parentData.country_code,
      schoolCode: school_code,
    });

    // console.log("props:", this.props);
    this.setState({ gradeList: this.props.gradeList });

    if (this.state.parentData.is_teacher) {
      // console.log("yes teacher", this.state.defaultChild);

      // console.log("school_code:", school_code);
      this.verifySchoolCode(null);
    }

    mixpanel.reset();
  };

  componentDidUpdate = (prevProps, prevState) => {
    //  console.log(prevProps)
    if (prevProps.gradeList !== this.props.gradeList) {
      this.setState({ gradeList: this.props.gradeList });
    }
    //  console.log(this.state.gradeList)
  };

  getStatesAndUnionTerritories = () => {
    return [
      "Andaman and Nicobar Islands",
      "Andhra Pradesh",
      "Arunachal Pradesh",
      "Assam",
      "Bihar",
      "Chandigarh",
      "Chhattisgarh",
      "Dadra and Nagar Haveli and Daman and Diu",
      "Delhi",
      "Goa",
      "Gujarat",
      "Haryana",
      "Himachal Pradesh",
      "Jammu and Kashmir",
      "Jharkhand",
      "Karnataka",
      "Kerala",
      "Ladakh",
      "Lakshadweep",
      "Madhya Pradesh",
      "Maharashtra",
      "Manipur",
      "Meghalaya",
      "Mizoram",
      "Nagaland",
      "Odisha",
      "Puducherry",
      "Punjab",
      "Rajasthan",
      "Sikkim",
      "Tamil Nadu",
      "Telangana",
      "Tripura",
      "Uttar Pradesh",
      "Uttarakhand",
      "West Bengal",
    ];
  };
  hasErrors = (fieldsError) => {
    return Object.keys(fieldsError).some((field) => fieldsError[field]);
  };

  excludeTeacherGrades = (list) => {
    // if not teacher then return list
    if (!this.state.is_teacher) {
      return list;
    }

    // get childData
    let child_data = LocalStorage.fetch.childData()?.list;
    for (let i = 0; i < child_data.length; i++) {
      let grade_id = child_data[i].grade.id;
      // exclude this grade_id from list
      list = list.filter((item) => item.id !== grade_id);
    }
    return list;
  };

  gradeNameMapping = () => {
    let gradeList = this.excludeTeacherGrades(this.props.gradeList)

    let grades = gradeList?.reduce((acc, grade) => {
      acc[grade.id] = grade.name;
      return acc;
    }, {});
    return grades;
  };

  transformData(data) {
    const gradeMap = {};

    data.forEach((item) => {
      let gradeNameMap = this.gradeNameMapping();

      const gradeName = gradeNameMap[item.grade]
      const gradeId = item.grade;

      if (!gradeName) {
        return; // Continue to the next item
      }

      // Create grade if it doesn't exist in the map
      if (!gradeMap[gradeId]) {
        gradeMap[gradeId] = {
          value: gradeId,
          title: gradeName,
          children: [],
        };
      }

      // Add the class to the grade's children
      gradeMap[gradeId].children.push({
        value: item.id,
        title: `${gradeName} - ${item.name}`,
      });
    });

    // Convert map to array
    return Object.values(gradeMap);
  }

  convertToTreeFormat = async () => {
    let gradeList = this.state.gradeList;
    // console.log("gradeList:", gradeList);
    gradeList = this.excludeTeacherGrades(gradeList);
    const recursiveConvert = async (list) => {
      return await Promise.all(
        list.map(async (item) => ({
          value: item.id,
          title: item.name,
          children: item.children
            ? await recursiveConvert(item.children)
            : await getClass(item.id, item.name),
        }))
      );
    };

    const getClass = async (grade_id, grade_name) => {
      const list = await this.props.getClass({
        school: this.state.school ? this.state.school.id : "",
        grade: grade_id,
      });
      let treeClassFormat = [];
      if (list && list.length > 0) {
        for (let idx = 0; idx < list.length; idx += 1) {
          let subclass = {
            value: list[idx].id,
            title: grade_name + " - " + list[idx].name,
            children: null,
          };
          treeClassFormat.push(subclass);
        }
      }

      // Return the processed data or do something with 'list' as needed
      return treeClassFormat;
    };

    const removeNoClassGrades = (objects) => {
      return objects.filter(
        (obj) =>
          obj.children !== null &&
          obj.children !== undefined &&
          obj.children.length !== 0
      );
    };

    let data = await recursiveConvert(gradeList);
    return removeNoClassGrades(data);
  };

  doYouHaveSchoolCode = (event) => {
    // Determine which button was clicked
    const choice = event.nativeEvent.submitter.innerText.toLowerCase();

    if (choice === "yes") {
      let payload = {};
      this.sendAnalytics(EVENT.ONBOARDING_GETSTARTED_SCHOOLCODE, payload);
      this.setState({ SchoolCodeSelected: true, schoolCodeYesNoPage: true });
    } else if (choice === "no") {
      this.setState({ SchoolCodeSelected: false, schoolCodeYesNoPage: true });
    }
  };

  schoolCodeVerifiedCompleteFunc = () => {
    this.setState({ schoolCodeVerifiedComplete: true });
  };

  continueSchool = (e) => {
    e.preventDefault();
    // let payload = {}
    // payload[EVENT.CHILD_SCHOOL] = this.state.school?.name
    // payload[EVENT.SCHOOL_TAG] = this.state.school?.school_tag
    // payload[EVENT.SCHOOL_CODE] = this.state.school?.school_code
    // this.eventList.push({ name: EVENT.ONBOARDING_SCHOOLCODE, data: payload })
    // this.sendAnalytics(EVENT.ONBOARDING_SCHOOLCODE,payload)
    this.setState({ schoolCodeVerified: true, SchoolCodeSelected: true });

    this.setState({ schoolCodeVerifiedComplete: true });
  };

  getSchoolcode = () => {
    return this.state.defaultChild?.school_code;
  };

  verifySchoolCode = async (e) => {
    let school_code = null;
    if (e) {
      e.preventDefault();
    }

    school_code = this.getSchoolcode();

    if (!school_code && !this.state.is_teacher) {
      school_code = this.state.schoolCode;
    }

    this.setState({ verifingSchool: true });
    const res = await this.props.getSchool(school_code);
    if (res) {
      this.setState({ verifingSchool: false, school: res });

      if(!this.state.is_teacher){
        const lang = await this.props.getLanguagesBySchool(res.id);
        this.setState({ schoolLanguages: lang });
      }
      else{
        const list = await this.props.getClass({
          school: this.state.school ? this.state.school.id : "",
        });
        const treeGrades = this.transformData(list);
        this.setState({ treeGrades: treeGrades });
      }
    } else {
      this.setState({
        verifingSchool: false,
        error: "Please enter a valid school code shared by your school",
      });
    }
  };

  gradeSelectionComplete = () => {
    this.setState({ gradeSelected: true });
  };

  handleUpdateState = (key, e) => {
    this.setState({ [key]: e.target.value, error: null });
  };
  childNameSubmit = (e) => {
    e.preventDefault();
    this.setState({ enteredName: true });
    this.setState({ loading: true });
    this.submitChildData();
  };
  treeselectOnChange = (newValue, label, extra) => {
    // check newValue is a string or array,if string then convert it to array
    if (typeof newValue === "string") {
      newValue = [newValue];
    }

    let selectedGradesList = this.getSelectedGrades(newValue);
    const isValid = selectedGradesList.length > 0; // Check if any grade is selected
    this.setState({
      selectedGrades: selectedGradesList,
      selectedSections: newValue,
      gradeSelectionValid: isValid,
    });
  };

  getSelectedGrades = (selectedSection) => {
    let selectedGradeIDs = new Set();
    // for(const section in selectedSection){
    //     // console.log("section:",section);
    //     let parentGrade = this.findParentValue(section);
    //     if(parentGrade) {
    //         selectedGradeIDs.push(parentGrade)
    //     }
    // }

    for (let idx = 0; idx < selectedSection.length; idx += 1) {
      let parentGrade = this.findParentValue(selectedSection[idx]);
      if (parentGrade) {
        selectedGradeIDs.add(parentGrade);
      }
    }
    return Array.from(selectedGradeIDs);
  };

  findParentValue = (sectionID, idx, array) => {
    let data = this.state.treeGrades;
    for (const item of data) {
      if (item.value === sectionID) {
        return item.value; // Found the value in the current level
      }
      if (item.children && item.children.length > 0) {
        for (const subItem of item.children) {
          if (subItem.value == sectionID) {
            return item.value;
          }
        }
      }
    }

    return null; // Value not found in the hierarchy
  };
  validate(name) {
    let n = name.trim();
    const re = /^[a-zA-Z0-9_ .]*$/;
    return re.test(n);
  }
  onChange = async (value) => {
    // Analytics.logEvents(EVENT.ONBOARDING_SELECT_GRADE)
    this.setState({ grade: value });

    if (this.state.schoolCodeVerifiedComplete) {
      const list = await this.props.getClass({
        school: this.state.school ? this.state.school.id : "",
        grade: value,
      });
      this.setState({ gradeSections: list });
    }
  };

  onSectionChange = (value) => {
    // console.log("onSectionChange", value);
    this.setState({ childSection: value });
  };

  sendAnalytics = (event, data) => {
    let payload = {};
    payload[EVENT.DEVICE_ID] = localStorage.getItem("device_id") || "";
    if (data) {
      payload = Object.assign({}, payload, data);
    }
    Analytics.logEvents(event, payload);
  };

  submitChildData = async (e) => {
    if (e) e.preventDefault();
    // console.log("submitChildData", this.state);
    let checkName = null;
    let childName = null;
    if (this.state.child) {
      checkName = this.validate(this.state.child);
      childName = this.state.child.trim();
    }
    // console.log("checkName", checkName, checkName);
    if (childName && childName !== "" && checkName) {
      let grades_array = [];
      // if this.state.grade is not a array then make it a array
      if (!Array.isArray(this.state.grade)) {
        grades_array.push(this.state.grade);
      } else {
        grades_array = this.state.grade;
      }
      var child_grades = null;
      if (this.state.grade) {
        child_grades = this.state.grade;
      } else {
        child_grades = this.state.selectedGrades;
      }

      const childData = {
        name: childName,
        grade: child_grades,
        is_teacher: this.state.is_teacher,
      };

      this.setState({
        disabledChildButton: true,
      });
      const res = null;
      const payload = {};
      if (!this.state.is_teacher) {
        let schoolLangObj = this.state.schoolLanguages.find(
          (sl) =>
            this.props.gradeList.find((grade) => grade.name === sl.grade).id ===
            this.state.grade
        );
        let schoolLangs = schoolLangObj.languages;
        for (let index = 0; index < schoolLangs.length; index++) {
          childData["language"] = schoolLangs[index].id;
          let childcreateRes = await this.props.createChild(childData, res);
          if (childcreateRes) {
            payload[EVENT.CHILD_GRADE] = childcreateRes?.result?.grade?.name;
            payload[EVENT.CHILD_ID] = childcreateRes?.result.id;
            payload[EVENT.CT_CHILD_NAME] = childcreateRes?.result?.name;
            
            this.sendAnalytics(EVENT.ONBOARDING_ADD_CHILD, payload);

            if (this.state.schoolCodeVerifiedComplete) {
              let childSchool = {};
              if (this.state.is_teacher) {
                childSchool = {
                  school: this.state.school ? this.state.school.id : "",
                  school_class: this.state.selectedSections,
                };
              } else {
                childSchool = {
                  school: this.state.school ? this.state.school.id : "",
                  school_class: [this.state.childSection],
                };
              }

              const childSchoolRes = await this.props.addSchool(childSchool);
            }
          }
        }
        window.location = "/";
      } else {
        let teacher_signup_data = {
          school: this.state.school ? this.state.school.id : "",
          school_class: this.state.selectedSections,
          name: childName,
          state: this.state.childState,
          contact_no: this.state.parentData?.contact_no,
          country_code: this.state.code,
        };

        let childRes = await this.props.createTeacherRequest(
          teacher_signup_data
        );

        if (childRes) {
          await userActions.getAndSetChild(store.dispatch, {}, null, true);
          window.location = "/";
        }
      }
    } else {
      this.setState({ error: "Invalid Child name" });
    }
  };

  render() {
    const { getFieldDecorator, getFieldsError } = this.props.form;
    const {
      is_teacher,
      gradeSelected,
      treeGrades,
      enteredName,
      child,
      statesData,
      school,
      verifingSchool,
      schoolCodeYesNoPage,
      SchoolCodeSelected,
      schoolCodeVerified,
      schoolCodeVerifiedComplete,
      loading,
    } = this.state;
    return (
      <div className="component-profile">
        <div className="description-card">
          <div className="flex">
            <p className="total-activities-text">ADD PROFILE</p>
            <img
              src={CloseBlackIcon}
              className="close-icon"
              alt="close"
              onClick={this.props.close}
            />
          </div>
          <div className="description-container">
            {/* {!is_teacher && !schoolCodeYesNoPage ? (
              <form onSubmit={this.doYouHaveSchoolCode}>
                <div className="otp-label">Do you have school code ?</div>
                <div className="d-flex">
                  <button
                    style={{
                      background: "#CB5499",
                      paddingTop: "5px",
                      paddingRight: "10px",
                      paddingLeft: "10px",
                      paddingBottom: "5px",
                      border: "#CB5499 2px solid",
                      borderRadius: " 6px",
                      color: "white",
                      marginTop: "15px",
                    }}
                    type="primary"
                    htmlType="submit"
                    className="school-code-yes-no"
                  >
                    Yes
                  </button>

                  <p style={{ padding: "10px" }}></p>
                  <button
                    style={{
                      background: "#CB5499",
                      paddingTop: "5px",
                      paddingRight: "15px",
                      paddingLeft: "15px",
                      paddingBottom: "5px",
                      border: "#CB5499 2px solid",
                      borderRadius: " 6px",
                      color: "white",
                      marginTop: "15px",
                    }}
                    type="primary"
                    htmlType="submit"
                    className="school-code-yes-no"
                  >
                    No
                  </button>
                </div>
              </form>
            ) : null} */}

            {!is_teacher &&
            // schoolCodeYesNoPage &&
            SchoolCodeSelected &&
            !schoolCodeVerifiedComplete ? (
              <Form layout="vertical">
                <div className="otp-label">Please enter school code</div>
                <form
                  onSubmit={
                    !school
                      ? this.verifySchoolCode
                      : !schoolCodeVerified
                      ? this.continueSchool
                      : this.schoolCodeVerifiedCompleteFunc
                  }
                >
                  <OtpInput
                    onChange={(schoolCode) => {
                      this.setState({ schoolCode: schoolCode, error: null });
                    }}
                    value={this.state.schoolCode}
                    numInputs={6}
                    containerStyle={{
                      display: "flex",
                      justifyContent: "space-between",
                      width: "300px",
                      marginBottom: "10px",
                    }}
                    shouldAutoFocus
                    separator={<span></span>}
                    inputStyle={otpBoxStyle}
                    isDisabled={verifingSchool}
                  />
                  <div style={{ color: "red", fontSize: 14 }}>
                    {this.state.error}
                  </div>
                  {school ? (
                    <Input size="large" value={school.name} disabled={true} />
                  ) : null}
                  <Button
                    type="primary"
                    disabled={verifingSchool}
                    htmlType="submit"
                  >
                    Continue
                  </Button>
                </form>
              </Form>
            ) : null}

            {is_teacher && !gradeSelected ? (
              <Form layout="vertical" onSubmit={this.gradeSelectionComplete}>
                <div className="otp-label" style={{ marginBottom: "10px" }}>
                  Pick the grades that you teach in
                </div>
                <div>
                  {/* FREAD-1237 */}
                  <div
                    style={{
                      // display: 'flex'//, alignItems: 'center'
                      width: "100%",
                    }}
                  >
                    <Form.Item className="form-item" style={{ margin: "0%" }}>
                      {getFieldDecorator("grade", {
                        rules: [
                          {
                            required: true,
                            message: "Please select grade",
                          },
                        ],
                      })(
                        <TreeSelect
                          showSearch
                          dropdownStyle={{
                            color: "black",
                            maxHeight: 230,
                            width: 170,
                            overflow: "auto",
                            zIndex: "100000",
                            left: 660,
                          }}
                          placeholder={
                            <span style={{ color: "grey" }}>Select grade</span>
                          }
                          allowClear
                          treeDefaultExpandAll
                          multiple={this.state.is_teacher ? true : false}
                          treeCheckable={true}
                          onChange={this.treeselectOnChange}
                          treeData={this.state.treeGrades}
                        />
                      )}
                    </Form.Item>
                  </div>
                  <span style={{ color: "red", fontSize: 14 }}>
                    {this.state.error}
                  </span>
                </div>
                <button
                  type="primary"
                  disabled={
                    !this.state.gradeSelectionValid ||
                    this.hasErrors(getFieldsError())
                  }
                  htmlType="submit"
                  className="continue-button"
                  // disabled={this.state.disabledOtpButton}
                  loading={this.state.isSendingOtp}
                  style={{
                    background: "#CB5499",
                    paddingTop: "5px",
                    paddingRight: "10px",
                    paddingLeft: "10px",
                    paddingBottom: "5px",
                    border: "#CB5499 2px solid",
                    borderRadius: " 6px",
                    color: "white",
                    marginTop: "15px",
                  }}
                >
                  Continue
                </button>
              </Form>
            ) : null}
            {
              // schoolCodeYesNoPage &&
              ((SchoolCodeSelected && schoolCodeVerifiedComplete) ||
                !SchoolCodeSelected) &&
              !gradeSelected &&
              !(is_teacher && SchoolCodeSelected) ? (
                <Form layout="vertical" onSubmit={this.gradeSelectionComplete}>
                  {!is_teacher ? (
                    <div className="otp-label" style={{ marginBottom: "10px" }}>
                      Pick the grade your child is in
                    </div>
                  ) : (
                    <div className="otp-label">
                      Pick the grades that you teach in
                    </div>
                  )}
                  <div className="grade-select d-flex">
                    <div style={{ width: "98%", height: 30 }}>
                      <Form.Item style={{ width: "250px", marginRight: "4px" }}>
                        {getFieldDecorator("grade", {
                          rules: [
                            {
                              required: true,
                              message: "Please select grade",
                            },
                          ],
                        })(
                          <Select
                            showSearch
                            mode={this.state.is_teacher ? "multiple" : "single"}
                            style={{
                              color: "black",
                              width: "250px",
                              height: 40,
                            }}
                            placeholder={
                              <span style={{ color: "grey" }}>
                                Select grade
                              </span>
                            }
                            optionFilterProp="children"
                            dropdownClassName="drop-down"
                            onChange={this.onChange}
                            onFocus={this.onFocus}
                            onBlur={this.onBlur}
                            onSearch={this.onSearch}
                          >
                            {this.state.gradeList &&
                              this.state.gradeList.length > 0 &&
                              this.state.gradeList.map((m, index) => (
                                <Option key={m.id} value={m.id}>
                                  {m.name}
                                </Option>
                              ))}
                          </Select>
                        )}
                      </Form.Item>
                    </div>

                    <div style={{ width: "50%", height: 30 }}>
                      {!is_teacher && this.state.gradeSections.length > 0 ? (
                        <Form.Item style={{ marginBottom: 0, width: "250px" }}>
                          {getFieldDecorator("section", {
                            rules: [
                              {
                                required: true,
                                message: "Please select section",
                              },
                            ],
                          })(
                            <Select
                              showSearch
                              mode={
                                this.state.is_teacher ? "multiple" : "single"
                              }
                              style={{ width: 250, height: 40 }}
                              placeholder={
                                <span style={{ color: "grey" }}>
                                  Select section
                                </span>
                              }
                              optionFilterProp="Section"
                              dropdownClassName="custom-dropdown"
                              onChange={this.onSectionChange}
                              onFocus={this.onFocus}
                              onBlur={this.onBlur}
                              onSearch={this.onSearch}
                            >
                              {this.state.gradeSections &&
                                this.state.gradeSections.length > 0 &&
                                this.state.gradeSections.map((m, index) => (
                                  <Option key={m.id} value={m.id}>
                                    {m.name}
                                  </Option>
                                ))}
                            </Select>
                          )}
                        </Form.Item>
                      ) : null}
                    </div>
                  </div>
                  {this.state.schoolLanguages.length > 0 &&
                  this.state.schoolLanguages.filter(
                    (sl) =>
                      this.props.gradeList.find(
                        (grade) => grade.name === sl.grade
                      ).id === this.state.grade
                  ).length > 0 ? (
                    <div className="school_languages_container">
                      {this.state.schoolLanguages
                        .find(
                          (sl) =>
                            this.props.gradeList.find(
                              (grade) => grade.name === sl.grade
                            ).id === this.state.grade
                        )
                        .languages?.map((lang) => {
                          return (
                            <div className="school_language_container">
                              <p>{lang.name}</p>
                            </div>
                          );
                        })}
                    </div>
                  ) : (
                    this.state.grade && (
                      <div className="school_language_container">
                        <p>English</p>
                      </div>
                    )
                  )}
                  <button
                    type="primary"
                    disabled={this.hasErrors(getFieldsError())}
                    htmlType="submit"
                    // disabled={this.state.disabledOtpButton}
                    loading={this.state.isSendingOtp}
                    style={{
                      background: "#CB5499",
                      paddingTop: "5px",
                      paddingRight: "10px",
                      paddingLeft: "10px",
                      paddingBottom: "5px",
                      border: "#CB5499 2px solid",
                      borderRadius: " 6px",
                      color: "white",
                      marginTop: "15px",
                    }}
                  >
                    Continue
                  </button>
                </Form>
              ) : null
            }

            {!enteredName && gradeSelected ? (
              <Form layout="vertical" onSubmit={this.childNameSubmit}>
                {!is_teacher ? (
                  <div className="otp-label" style={{ marginBottom: "20px" }}>
                    Enter your child's name
                  </div>
                ) : (
                  <div className="otp-label" style={{ marginBottom: "10px" }}>
                    Enter your name
                  </div>
                )}
                <Form.Item style={{ margin: 0 }}>
                  <Input
                    className={"input-class"}
                    size="large"
                    value={child}
                    placeholder={
                      !is_teacher ? "Your child's name" : "Your name"
                    }
                    onChange={(e) => this.handleUpdateState("child", e)}
                  />
                  <span style={{ color: "red", fontSize: 14 }}>
                    {this.state.error}
                  </span>
                </Form.Item>
                <button
                  type="primary"
                  disabled={!child || this.hasErrors(getFieldsError())} // Disable if input is empty
                  htmlType="submit"
                  loading={this.state.isSendingOtp}
                  style={{
                    background: "#CB5499",
                    paddingTop: "5px",
                    paddingRight: "10px",
                    paddingLeft: "10px",
                    paddingBottom: "5px",
                    border: "#CB5499 2px solid",
                    borderRadius: " 6px",
                    color: "white",
                    marginTop: "15px",
                  }}
                >
                  Continue
                </button>
              </Form>
            ) : null}

            {loading ? (
              <img
                style={{ height: "5rem" }}
                className="img-recorder"
                src={loader}
                alt="loading"
              />
            ) : null}
          </div>
        </div>
      </div>
    );
  }
}

const connectedComponent = connect(
  ({ user }) => ({ ...user }),
  (dispatch) => bindActionCreators({ ...userActions }, dispatch)
)(Addprofile);

export default Form.create()(connectedComponent);
