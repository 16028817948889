import headTecherIcon from "../../../../../assets/image/graduation-cap.png";
import DefaultUserIcon from "../../../../../assets/image/default-user.png";
import TeamsIcon from "../../../../../assets/image/team.svg";
import React from "react";
import { connect } from "react-redux";
import './index.scss'
import * as EVENT from "../../../../../utils/eventKeys";


const TeacherStudentCard = ({ reduxData, openChildrenModal, ...props }) => {
    const student = reduxData?.student;
    const batchDetails = student?.batchDetails;
    const teacherInfo = (batchDetails?.length > 0) ? batchDetails[0]?.teacher : null;
    return <div
        className="osahan-account-page-left shadow-sm bg-white rounded-6 mt-4 rounded border-bottom head-teacher">
        <div className="p-4 teacher-section">
            <div className="osahan-user">
                <div className="osahan-user-media">
                    <div className="osahan-user-media-body">

                        <div className="cardTitle-row">
                            <div className="cardTitle cardTitleSB">
                                <div>
                                    <img className="headTecherIcon" src={TeamsIcon} />
                                    MY STUDENTS
                                </div>
                                <a onClick={() => {
                                   
                                    openChildrenModal();
                                }}
                                    //FREAD-1342
                                    id="FLV_BATCH_VIEW_ALL"
                                //FREAD-1342

                                >
                                    View All
                                </a>
                            </div>
                            <div className="profile-icons">
                                <img
                                    src={DefaultUserIcon}
                                    style={{width: "15%"}}
                                    alt="No Student Image"
                                />
                                <img
                                    src={DefaultUserIcon}
                                    style={{width: "15%"}}
                                    alt="No Student Image"
                                />
                                <img
                                    src={DefaultUserIcon}
                                    style={{width: "15%"}}
                                    alt="No Student Image"
                                />
                                <img
                                    src={DefaultUserIcon}
                                    style={{width: "15%"}}
                                    alt="No Student Image"
                                />
                            </div>
                        </div>
                        <div className="friendMember">
                            {
                                <BatchMates student={reduxData?.student}
                                    data={batchDetails?.length > 0 ? batchDetails[0].children : []} />
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
}

const BatchMates = ({ data, student }) => {
    if (data && data.length > 0) {
        return data.filter(child => child?.id !== student?.id)
            .map((child, index) => (
                <img
                    className="friendPic"
                    src={child?.image ? child?.image : DefaultUserIcon}
                    alt=""
                />
            ));
    } else return null
}

export default connect(
    (state) => ({ reduxData: { ...state } }),
    null
)(TeacherStudentCard);
