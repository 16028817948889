import React, { Component } from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import TruncateString from 'utils/truncate';
import FreadoIcon from "assets/image/freado.svg";
import UnlockIcon from "assets/image/padlock-unlock.svg";
import LockIcon from "assets/image/padlock.svg";
import BookmarkActive from 'assets/image/bookmark-active.svg';
import BookmarkInActive from 'assets/image/bookmark-inactive.svg';
import { bookmarkStory } from 'store/story/actions'
import { bookmarkFeed } from 'store/home/actions'
import { getQuizList } from 'store/quiz/actions'
import { Icon } from "antd";
import './Storycard.scss';
import { Analytics } from '../../services/analytics'
import { v4 as uuidv4 } from 'uuid';
import * as EVENT from '../../utils/eventKeys'
import contentTickComplete from "assets/image/unitTickComplete.png";

const gradient = 'linear-gradient(359deg, #552d83, #432870 64%, #361e5f)';
const UNLOCKED = 'unlocked';
const LOCKED = 'locked';
const STORY_ICON = {
  read: "read",
  watch: "video-camera",
  listen: "sound",
  senctioned: ""
}
const view_id = uuidv4()
class Storycard extends Component {

  bookmark = e => {
    e.stopPropagation();
    const { bookmarkStory, data } = this.props;
    bookmarkStory(data);
    this.sendAnalytics(EVENT.LIKE_STORY, data);
    if (this.props.cardType == 'feed') {
      this.props.bookmarkFeed({ header: this.props.section, id: data.id })
    } else {
      this.props.getQuizList()
      if (this.props.bookmark) { // To update parent data from collections
        this.props.bookmark(data.id)
      }
    }

  }

  sendAnalytics = (event, data) => {
    let payload = {}
    payload[EVENT.STORY_TYPE] = data?.story_type
    payload[EVENT.STORY_NAME] = data?.name
    payload[EVENT.STORY_ID] = data?.id
    payload[EVENT.SECTION_ID] = this.props?.section;
    payload[EVENT.SOURCE_PAGE_ID] = this.props.source // event source key
    payload[EVENT.PARTNER_SOURCE] = data?.source // source in data response
    payload[EVENT.VIEW_ID]=view_id // create a uuid for unique story
    payload[EVENT.STORY_LIKE] = !data.is_liked
    Analytics.logEvents(event, payload);
  }

 getDescription = (data) => (
  <div className="text-white text-12 story-description">
    {TruncateString(data.description, 60)}
  </div>
);

  render() {
    const { data, type, size, from } = this.props;
    return data ? (
      <div
        className="storycard-container"
        style={{
          backgroundImage: data.image ? 'url("' + data.image + '")' : gradient,
          width: from == "dashboard" ? "86%" : "100%",
          margin: from !== "dashboard" && "0 0 0 0",
          opacity: data?.status === "completed" ? 0.6 : 1,
        }}
      >
        <div className="story-data">
          {data?.status === "completed" && (
            <img
              className="content_completed"
              src={contentTickComplete}
              alt="completed"
            />
          )}
          {type === 0 && (
            <img
              className="bookmark-icon"
              src={data.is_liked ? BookmarkActive : BookmarkInActive}
              alt="bookmark"
              onClick={this.bookmark}
            />
          )}
          {data.story_type && (
            <StoryType
              icon={STORY_ICON[data.story_type]}
              text={data.story_type}
              size={size}
            />
          )}
          {type === 1 &&
            (data.activity_pack === null ? (
              <Pill icon={FreadoIcon} text={data.freadom_point} size={size} />
            ) : (
              <Pill
                icon={data.status === UNLOCKED ? UnlockIcon : LockIcon}
                size={size}
              />
            ))}
          <div className="bottom-div">
            <div className="title">
              <div className="story-name">{data.name}</div>
              {type === 0 && (
                <div className="story-author">
                  Written by <b>{data.author_name}</b>
                </div>
              )}
            </div>

          </div>
          {(type === 1 || type === 3 || type === 2) &&
            this.getDescription(data)}
          {type === 3 && (
            <>
              <div className="text-white text-12 story-description">
                {data.date}
              </div>
            </>
          )}
          {/* <div className="story-publisher">Published by Penguin India</div> */}
        </div>
      </div>
    ) : null;
  }
};

const Pill = props => (
  <div className="read-time" style={{ width: props.text ? '' : '26px', height: props.text ? '' : '26px', borderRadius: props.text ? '' : '50%' }}>
    <img src={props.icon} style={{ margin: props.text ? '0 6px 0 0' : 'auto' }} alt="" />
   {props.text && <div style={{ width: 60 }}>{props.text || ''}</div>}
  </div>
)
const StoryType = props => (
  <div className="story-type" style={{ width: props.text ? '' : '26px', height: props.text ? '' : '26px', borderRadius: props.text ? '' : '50%' }}>
    <Icon type={props.icon} style={{ paddingRight: 5 }} />
    <span style={{ textTransform: "capitalize" }}>{props.text || ''}</span>
  </div>
)

Storycard.defaultProps = {
  type: 0, // Type ((0 => Story) ), ((1 => Activity) )
}

export default connect(
  null,
  dispatch => bindActionCreators({ bookmarkStory, bookmarkFeed, getQuizList }, dispatch)
)(Storycard);