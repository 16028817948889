import React, { useState, useEffect } from "react";
import { Input, List, Avatar } from "antd";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import { getContentSearch } from "../../services/story";
import * as storyActions from "store/story/actions";
import { Spin } from "antd";
import "./contentSearch.scss";
import { storage as LocalStorage } from "services/config/storage";
import _debounce from "lodash/debounce";

let Timer;
function ContentSearch({
  shwContentSearch,
  topStory,
  match,
  isTeacherDashboard,
  history,
  setDataClick,
  selectOption,
}) {
  const [shwCat, setShwCat] = useState(false);
  const [srchVal, setSrchVal] = useState("");
  const [prevSearch, setPrevSearch] = useState("");
  const [type, setType] = useState("");
  const [offset, setOffset] = useState(0);
  const [has_next, setHas_next] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [types, setTypes] = useState([]);
  const [lists, setList] = useState([]);

  let defaultChild = LocalStorage.fetch.defaultChild();

 useEffect(() => {
   if (srchVal.trim() !== "") {
     getLists(srchVal, type, setList, setOffset, setHas_next, prevSearch);
   }
 }, [type, srchVal]);

  useEffect(() => {
    if (offset !== 0 && isLoading) {
      add_next_lists(
        srchVal,
        type,
        setList,
        lists,
        offset,
        has_next,
        setLoading,
        setOffset,
        setHas_next
      );
    } else setLoading(false);
  }, [isLoading]);
  return (
    <>
      <div
        className="modal_Srch_main"
        style={{
          zIndex: 99,
        }}
      >
        <Input
          allowClear={true}
          value={srchVal}
          className="modal_input-search"
          onPressEnter={(e) => SearchKeyUp(e.target.value, match.path)}
          onChange={(e) => {
            e.preventDefault();
            const value = e?.target.value;
            setShwCat(value !== "" ? true : false);
            setSrchVal(value);

            if (value === "") {
              getCount.cancel(); // Cancel pending API calls when clearing
              setTypes([]);
              setType("");
              setPrevSearch(""); // Reset previous search when cleared
            } else if (value !== prevSearch) {
              getCount(value, setTypes, setType, prevSearch, setPrevSearch);
            }
          }}
          placeholder={`Search for Stories, Activities,${
            defaultChild.language === "Hindi" ? "" : " News"
          } and Quiz`}
          style={{ float: isTeacherDashboard ? "inline-start" : "right" }}
        />
        <div className="modal_filter-relate">
          <div
            className="filter-absolute"
            style={{
              display: shwCat ? "block" : "none",
            }}
          >
            {types.length !== 0 && (
              <div className="srch_cat">
                {types.filter((type) => type.name === "Stories").length > 0 ? (
                  <button
                    onClick={() => {
                      setType("story");
                    }}
                    type="button"
                    className={"btn-cat " + (type === "story" ? "actives" : "")}
                  >
                    Stories (
                    {
                      types.filter((type) => type.name === "Stories")[0]
                        ?.doc_count
                    }
                    )
                  </button>
                ) : null}
                {types.filter((type) => type.name === "Activities").length >
                0 ? (
                  <button
                    onClick={() => {
                      setType("activity");
                    }}
                    type="button"
                    className={
                      "btn-cat " + (type === "activity" ? "actives" : "")
                    }
                  >
                    Activities (
                    {
                      types.filter((type) => type.name === "Activities")[0]
                        ?.doc_count
                    }
                    )
                  </button>
                ) : null}

                {types.filter((type) => type.name === "News Freads").length >
                  0 && defaultChild?.language !== "Hindi" ? (
                  <button
                    onClick={() => {
                      setType("news_fread");
                    }}
                    className={
                      "btn-cat " + (type === "news_fread" ? "actives" : "")
                    }
                    type="button"
                  >
                    News (
                    {
                      types.filter((type) => type.name === "News Freads")[0]
                        ?.doc_count
                    }
                    )
                  </button>
                ) : null}

                {types.filter((type) => type.name === "Quiz").length > 0 ? (
                  <button
                    onClick={() => {
                      setType("quiz");
                    }}
                    className={"btn-cat " + (type === "quiz" ? "actives" : "")}
                    type="button"
                  >
                    Quiz (
                    {types.filter((type) => type.name === "Quiz")[0]?.doc_count}
                    )
                  </button>
                ) : null}
              </div>
            )}
            <div style={{ height: "100%" }}>
              {!types.filter((type) => type.name === "Stories").length > 0 &&
                type === "story" && (
                  <div className="teacher-no-data" style={{ color: "#e31e24" }}>
                    No results found! It seems your search didn't match any
                    content. Try again with different words.
                  </div>
                )}
              {!types.filter((type) => type.name === "Activities").length > 0 &&
                type === "activity" && (
                  <div className="teacher-no-data" style={{ color: "#e31e24" }}>
                    No results found! It seems your search didn't match any
                    content. Try again with different words.
                  </div>
                )}
              {!types.filter((type) => type.name === "News Freads").length >
                0 &&
                defaultChild?.language !== "Hindi" &&
                type === "news_fread" && (
                  <div className="teacher-no-data" style={{ color: "#e31e24" }}>
                    No results found! It seems your search didn't match any
                    content. Try again with different words.
                  </div>
                )}
              {!types.filter((type) => type.name === "Quiz").length > 0 &&
                type === "quiz" && (
                  <div className="teacher-no-data" style={{ color: "#e31e24" }}>
                    No results found! It seems your search didn't match any
                    content. Try again with different words.
                  </div>
                )}
              {!types.filter((type) => type.name === "Packs").length > 0 &&
                type === "activity_packs" && (
                  <div className="teacher-no-data" style={{ color: "#e31e24" }}>
                    No results found! It seems your search didn't match any
                    content. Try again with different words.
                  </div>
                )}
              {!types.filter((type) => type.name === "Features").length > 0 &&
                type === "features" && (
                  <div className="teacher-no-data" style={{ color: "#e31e24" }}>
                    No results found! It seems your search didn't match any
                    content. Try again with different words.
                  </div>
                )}

              {srchVal !== "" && lists.length === 0 && type === "" && (
                <div className="teacher-no-data" style={{ color: "#9134d8" }}>
                  No results found! It seems your search didn't match any
                  content. Try again with different words.
                </div>
              )}

              {type !== "" && lists.length > 0 && (
                <div
                  className="stories_list list_flow"
                  onScroll={(event) =>
                    Scroll_Result(event, setLoading, has_next)
                  } //style={{display:shwstory?'block':'none'}}
                >
                  <div style={{ height: "100%" }}>
                    <List
                      itemLayout="horizontal"
                      dataSource={lists}
                      renderItem={(story) => (
                        <List.Item
                          style={{ pointer: "cursor" }}
                          onClick={() => {
                            LocalStorage.set.recentSearchData({
                              _index: story?._index,
                              _source: { id: story?._id },
                              _type: story?._type,
                              _name: story?._source?.name,
                              image: story?._source?.image,
                            });
                            SearchKeyUp(story?._source?.name, match.path);
                            setDataClick(true);
                            selectOption([
                              {
                                _index: story?._index,
                                _source: { id: story?._id },
                                _type: story?._type,
                                _name: story?._source?.name,
                              },
                            ]);
                          }}
                        >
                          <List.Item.Meta
                            avatar={<Avatar src={story?._source?.image} />}
                            title={story?._source?.name}
                            description=""
                          />
                        </List.Item>
                      )}
                    />
                    {isLoading && (
                      <div
                        className="teacher-no-data"
                        style={{ color: "#e31e24" }}
                      >
                        Loading ...
                        <Spin />
                      </div>
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
const Scroll_Result = (e, setLoading, has_next) => {
  const el = e.target; //.documentElement;
  //return console.log(el);
  const bottom = el.scrollHeight - el.scrollTop === el.clientHeight;
  if (bottom) {
    console.log("we are in the bottom");
    //Offset code below
    has_next && setLoading(true);
  }
};

const determineType = (counts, setType) => {
  if (!counts || counts.length === 0) {
    setType(""); // No results found, reset type
    return;
  }
  const story = counts.find((item) => item.key === "story");
  const activity = counts.find((item) => item.key === "activity");

  if (story) {
    setType("story");
  } else if (activity) {
    setType("activity");
  } else {
    setType(counts[0]?.key || ""); // Set to the first available type
  }
};

const getCount = _debounce(
  async (value, setTypes, setType, prevSearch, setPrevSearch) => {
    if (value === "" || value === prevSearch) return; // Prevent duplicate API calls

    setPrevSearch(value); // Update previous search value
    let queryParams = "query=" + value;
    let res = await getContentSearch(queryParams);

    if (res?.result?.counts) {
      setTypes(res.result?.counts);
      determineType(res?.result?.counts, setType);
    }
  },
  500
);

const getLists = _debounce(
  async (value, index_type, setList, setOffset, setHas_next, prevSearch) => {
    setList([]);
    if (value === "" || index_type === "") return;

    let queryParams = "query=" + value + "&index_type=" + index_type;
    let res = await getContentSearch(queryParams);
    //console.log('Alert',res)
    if (res?.result?.documents?.length > 0) {
      setList(res?.result?.documents);
      setOffset(res?.next_page);
      setHas_next(res?.has_next);
      //setType(index_type);
    } else {
      setList([]);
    }
  },
  500
);

const add_next_lists = async (
  value,
  index_type,
  setList,
  lists,
  offset,
  has_next,
  setLoading,
  setOffset,
  setHas_next
) => {
  //setList([]);
  if (value === "" || index_type === "") return setLoading(false);

  let queryParams =
    "query=" + value + "&index_type=" + index_type + "&offset=" + offset;
  let res = await getContentSearch(queryParams);
  //console.log('Alert',res)
  if (res?.result?.documents?.length > 0) {
    setOffset(res?.next_page);
    setHas_next(res?.has_next);
    setList([...lists, ...res?.result?.documents]);
    setLoading(false);
  } else {
    setLoading(false);
  }
};

const SearchKeyUp = (value, path) => {
  if (value !== "") {
    clearTimeout(Timer);
  }
};

export default connect(
  ({ story, activity, addAssignment }) => ({
    ...story,
    ...activity,
    ...addAssignment,
  }),
  (dispatch) => bindActionCreators({ ...storyActions }, dispatch)
)(withRouter(ContentSearch));
