import React, { Component } from "react";
import { connect } from "react-redux";
import { storage as LocalStorage } from "../../../../../services/config/storage";
import "./index.scss";
import writingIcon from "../../../../../assets/image/writing.png";
import { Link } from "react-router-dom";
import { EditIcon } from "../../../../../assets/image/svg";
import * as EVENT from "../../../../../utils/eventKeys";
import { Button, Form, Input, Select, TreeSelect, Menu } from "antd";
import { Dropdown } from "antd";
import { bindActionCreators } from "redux";
import * as teacherActions from "store/teacher/actions";
import DefaultUserIcon from "../../../../../assets/image/default-user.png";
import * as userActions from "store/user/actions";
import leaderboard_child from "../../../../../views/Leaderboard/leaderboard_child.png"
import teacher_default from "../../../../../assets/image/teacher-default.png"
import { FaEdit } from "react-icons/fa";

const { Option } = Select;

class UserCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      freadomChild: {},
      currentChild: {},
      childSchoolClasses: [],
      section: "",
    };
  }

  componentDidMount() {
    console.log("props-usercard", this.props);
    let childData = LocalStorage.fetch.childData();
    console.log("childData", childData);

    let currentChild = LocalStorage.fetch.defaultChild();
    this.setState({
      freadomChild: childData,
      currentChild: currentChild,
      childSchoolClasses: currentChild?.school_class || [],
    });

    console.log("current:", currentChild);
    console.log("classes", this.state.childSchoolClasses);
    console.log("out");
  }

  componentDidUpdate(prevProps, prevState) {
    // Check if childSchoolClasses has been updated and is now available
    if (prevState.childSchoolClasses.length === 0 && this.state.childSchoolClasses.length > 0) {
      console.log("Child school classes loaded.");
      this.setDefaultSection();
    }
  }

  // Function to set the default section once childSchoolClasses is available
  setDefaultSection = () => {
    console.log("2", this.state.childSchoolClasses.length )
    const { childSchoolClasses } = this.state;
    if (childSchoolClasses.length > 0) {
      console.log("in")
      const defaultSection = childSchoolClasses[0].id;
      console.log("def",defaultSection);
      this.setState({ section: defaultSection });
      this.props.switchTeacherClass(defaultSection);
      this.props.childUpdate(childSchoolClasses[0].name);
    }
  };

  handleMenuClick = (e) => {
    // Handle menu item click here
    console.log("handleMenuClick", e.key);
  };

  onChange = async (key,value) => {
    console.log("setting the section value", key,value);
    console.log("child:",value.props.children);
    this.setState({ section: key });
// this.props.setSchoolClass(value);
    this.props.switchTeacherClass(key);
    this.props.childUpdate(value.props.children);
    console.log("hi",value.props.children);
  };

  render() {
    const { freadomChild, currentChild, childSchoolClasses, section } =
      this.state;



    return (
      <>
        <div className="profile-card">
          <div className="profile-pic">
            <img
              src={currentChild?.image ? currentChild.image : teacher_default}
              style={{
                height: 123,
                width: 123,
                borderRadius: currentChild?.image ? 50 : 0
              }}
              alt="Student Image"
            />

          </div>
          <div className="profile-details">
            <div className="teacher-name">{currentChild?.name}</div>
            <div className="school-name">{currentChild?.school_name}</div>
            <div className="school-name">{currentChild?.language}</div>
          </div>

          <div className="grade-section">
            <div className="grade row">
              {/* <img
                src={writingIcon}
                // style={{ marginLeft: "20%" }}
                alt="edit grade"
              ></img> */}
              <FaEdit className="edit_icon"/>
              <div className="grade-name pl-1">{currentChild?.grade?.name}</div>
            </div>
            <div className="section" >
              <Form.Item style={{ marginBottom: 0,width:'100%' }}>
                <Select
                  className="select"
                  showSearch
                  style={{
                    width: '100%',
                    height: 40,
                    fontSize: 13,
                  }}
                  placeholder={<span style={{ color: 'purple', fontWeight: 'bold' }}>Sections</span>}
                  optionFilterProp="children"
                  dropdownClassName="drop-down"
                  listHeight={120}
                  onChange={this.onChange}
                  value={section} // Controlled component - value from state
                >
                  {childSchoolClasses.map((m) => (
                    <Option key={m.id} value={m.id}>
                      {m.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </div>
          </div>
          <div className="pt-2"></div>
        </div>
      </>
    );
  }
}

export default connect(
  ({ teacher,user }) => ({
    ...teacher, ...user
  }),
  (dispatch) =>
    bindActionCreators(
      {
        ...teacherActions, ...userActions
      },
      dispatch
    )
)(UserCard);
