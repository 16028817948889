import React, { useState } from 'react'
import './searchModal.scss'
import { IoMdClose } from "react-icons/io";
import ContentSearch from './ContentSearch'
import { Input, List, Avatar } from "antd";
import { storage as LocalStorage } from "services/config/storage";
import { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as storyActions from "store/story/actions";

const SearchModal = ({onClose,dataClick,setDataClick,history}) => {
    const [data, setData] = useState(LocalStorage.fetch.recentSearchData())

    useEffect(()=>{
        setData(LocalStorage.fetch.recentSearchData())
        setDataClick(false)
    },[dataClick])

    const selectOption = async (value) => {
        console.log("value", value);
        if (value && value.length > 0) {
            if (value[0]._index === "activity") {
                history.push(`/activity/${value[0]._source.id}`);
            } else if (value[0]._index === "story") {
                history.push(`/story/${value[0]._source.id}`);
            } else if (value[0]._index === "quiz") {
                history.push(`/quiz/${value[0]._source.id}`);
            } else if (value[0]._index === "news_fread") {
                history.push(`/news/${value[0]._source.id}`);
            }
        }
    };

  return (
    <div className='search_modal_container'>
        <div className='search_modal_header'>
            <IoMdClose onClick={onClose} className='search_modal_close_icon'/>
        </div>
        <div className='search_modal_body'>
            {/* <h2>Freadom Search</h2>
            <p>Search your favourite content across different varieties.</p> */}
            <ContentSearch setDataClick={setDataClick} selectOption={selectOption}/>

            {
                data?.length>0 &&
              <div
                  className="stories_list list_flow"
                  >
                  <p className='recent_history_heading'>Recent Searches:</p>
                  <div>
                      <List
                          itemLayout="horizontal"
                          dataSource={data?.slice(0,7)}
                          renderItem={(story) => (
                              <List.Item
                                  style={{ pointer: "cursor" }}
                                  onClick={()=>{
                                      selectOption([
                                          {
                                              _index: story?._index,
                                              _source: story?._source,
                                              _type: story?._type,
                                              _name: story?.name,
                                          },
                                      ]);
                                  }}
                              >
                                  <List.Item.Meta
                                      avatar={<Avatar src={story?.image} />}
                                      title={story?._name}
                                      description=""
                                  />
                              </List.Item>
                          )}
                          locale={{ emptyText: 'No recent search history found.' }}
                      />
                  </div>
              </div>
            }



        </div>
    </div>
  )
}

export default connect(
    ({ story, activity, addAssignment }) => ({ ...story, ...activity, ...addAssignment }),
    (dispatch) => bindActionCreators({ ...storyActions }, dispatch)
)(withRouter(SearchModal));
