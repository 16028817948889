import React, { useEffect, useMemo, useRef } from 'react'
import { useState } from 'react'
import { ReactReader, ReactReaderStyle } from 'react-reader';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as storyActions from "store/story/actions";
import _debounce from 'lodash/debounce';
import { Button, Dropdown, Menu, Modal, Slider, Tooltip } from 'antd'

import './EpubReader.scss'
import useSyllables from '../../hooks/useSyllables';
import MeaningModal from '../meaningModal/MeaningModal';
import SyllablesModal from '../syllablesModal/SyllablesModal';
import useTTSComponent from '../../hooks/useTTS';
import PlaybackRateIcon from 'assets/icons/playback_rate.svg'
import ListenIcon from 'assets/icons/listen.svg'
import RecordIcon from 'assets/icons/record.svg'
import ZoomInIcon from 'assets/icons/zoom-in.svg'
import ZoomoutIcon from 'assets/icons/zoom-out.svg'
import StopRecordIcon from 'assets/icons/stop-record.svg'
import SelectVoiceIcon from 'assets/icons/select-voice.svg'
import RightArrowIcon from 'assets/icons/right_arrow.svg'
import LeftArrowIcon from 'assets/icons/left_arrow.svg'
import PauseAudioIcon from 'assets/icons/pause-audio.svg'
import EpubButton from './EpubButton';
import { getTextNodes } from '../../utils/commonFuncs';
import * as EVENT from '../../utils/eventKeys'

const EpubReader = ({ url, handlePrevFunc, handleNextFunc, getMeaningsfromDict, bookLoadedFunc, renditionRef, setIsLoaded, isLoaded, currentPageIndex, handleSelectedVoice, lastPage, viewerRef, tocLength, setLocationChanged, selections, setSelections, selectedVoice, isRecording, startRecording, stopRecording, source, readAloudFunc, isAudioLoading: isLoadingAudio, readAloudAudioRef, playbackRate = 1, setPlayBackRate, currentWordIndex, showButtons, isReadAloud, setIsReadAloud, finishStory, showingLoader, isPaused, setIsPaused, sendStoryAnalytics = null, dataStory }) => {

    const [location, setLocation] = useState(null);
    const [openShowMeaningModal, setOpenShowMeaningModal] = useState(false)
    const [openShowSyllablesModal, setOpenShowSyllablesModal] = useState(false)
    const [meaningData, setMeaningData] = useState(null)
    const [tooltipPosition, setTooltipPosition] = useState({ top: 0, left: 0 });
    const [showTooltip, setShowTooltip] = useState(false);
    const [loadingMeanings, setLoadingMeanings] = useState(false);
    const [isAudioLoading, setIsAudioLoading] = useState(false)
    // const [isReadAloud, setIsReadAloud] = useState(false)
    const [fontSize, setFontSize] = useState(source === 'ReadandSpeak' ? 1 : 1.2)
    const [isSliderOpen, setOpenSlider] = useState(false)
    const [isSelectVoicesDropdownOpen, setIsSelectVoicesDropdownOpen] = useState(false)
    // const [playbackRate, setPlaybackRate] = useState(1)
    const [visible, setVisible] = useState(false);

    const tooltipRef = useRef(null)
    const audioRef = useRef(null)
    const textNodesRef = useRef([])

    const { generateTTS, handleTimeUpdate, isLoading } = useTTSComponent(audioRef)
    const { getSyllables, syllables } = useSyllables()


    useEffect(() => {
        const timer = setTimeout(() => {
            setVisible(false);
        }, 2000); // Hide after 2 seconds

        return () => clearTimeout(timer);
    }, [location]);

    const ownStyles = {
        ...ReactReaderStyle,
        arrow: {
            ...ReactReaderStyle.arrow,
            display: 'none'
        },
        typography: {
            ...ReactReaderStyle.typography,
            fontSize: '500%'
        }
    }

    const getMeaning = async (selections) => {
        if (selections?.text?.length > 0) {
            setLoadingMeanings(true)
            const res = await getMeaningsfromDict(selections?.text)
            if (res) {
                setMeaningData(res?.data)
            }
            setLoadingMeanings(false)
        }
    }

    const cleanText = (text) => {
        return text.replace(/[^a-zA-Z0-9\s]/g, '');
    };

    const closeMeaningModal = () => {
        setOpenShowMeaningModal(false)
    }

    const closeSyllablesModal = () => {
        setOpenShowSyllablesModal(false)
    }

    const onLocationChanged = (epubcfi) => {
        setLocation(epubcfi);
        setLocationChanged(true)
    };

    useEffect(() => {
        if (!isLoading && isAudioLoading) {
            setIsAudioLoading(false)
            audioRef.current.play()
        }
    }, [isLoading])

    useEffect(() => {
        if (renditionRef.current) {
            const debouncedSetRenderSelection = _debounce(async (cfiRange, contents) => {
                if (renditionRef.current) {
                    const selection = contents.window.getSelection();
                    if (!selection.rangeCount) return;

                    const range = selection.getRangeAt(0);
                    const rect = range.getBoundingClientRect();

                    if (!rect.width || !rect.height) return;
                    // console.log(renditionRef.current.getRange(cfiRange), rect, contents.window.scrollY, contents.window.scrollX, window.screenX, window.screenY)
                    const cleanedText = cleanText(renditionRef.current.getRange(cfiRange).toString())
                    // const range = renditionRef.current.getRange(cfiRange);
                    // const rect = range.getBoundingClientRect();
                    const iframe = viewerRef.current.querySelector('iframe');
                    const iframeRect = iframe ? iframe.getBoundingClientRect() : { top: 0, left: 0 };
                    const epubScrollY = contents.document.documentElement.scrollTop;
                    // console.log(iframeRect, iframe, epubScrollY)
                    setSelections(
                        {
                            text: cleanedText,
                            cfiRange,
                            rect
                        }
                    );
                    setTooltipPosition({
                        // top: rect.y + contents.window.scrollY + iframeRect.top -30, // Adjusted to show above selection
                        // left: rect.x + rect.width + 40 + contents.window.scrollX,
                        top: rect.top + iframeRect.top + contents.window.scrollY - 40, // Adjusted to show above selection
                        left: rect.left + iframeRect.left + contents.window.scrollX,
                    });
                    setShowTooltip(true);
                    renditionRef.current.annotations.add(
                        'highlight',
                        cfiRange,
                        {},
                        (e) => console.log('click on selection', cfiRange, e),
                        'hl',
                        { fill: '#c899eb', 'fill-opacity': '0.5', 'mix-blend-mode': 'multiply' }
                    )
                    // const selection = contents.window.getSelection();
                    //         const range = selection.getRangeAt(0);
                    //         const rect = range.getBoundingClientRect();
                    // console.log(selection);
                    selection.removeAllRanges();

                }
            }, 500);
            renditionRef.current.on('selected', debouncedSetRenderSelection)
            return () => {
                renditionRef.current.off('selected', debouncedSetRenderSelection)
            }
        }
    }, [setSelections, selections])

    useEffect(() => {
        setMeaningData(null)
        getMeaning(selections)
        generateTTS(selections?.text, null, selectedVoice)
        getSyllables(selections?.text)
        // let syllables = useSyllables(selections?.text)
        // setSyllables(syllables)
    }, [selections])

    useEffect(() => {
        if (renditionRef.current) {
            renditionRef.current.themes.fontSize(`${fontSize}rem`);
        }
    }, [fontSize]);

    useEffect(() => {
        if (viewerRef.current && isLoaded) {
            setTextNodes()
        }
    }, [isLoaded])

    useEffect(() => {

        if (viewerRef.current) {
            // const doc = viewerRef.current.querySelector('iframe')?.contentWindow.document
            const doc_body = viewerRef.current.querySelector('iframe')?.contentWindow?.document?.body
            const words = doc_body?.getElementsByTagName('span');
            let currentWord = ''
            for (let i = 0; i < words?.length; i++) {
                words[i].classList.remove('highlight');
                words[i].style.background = "none"
            }
            currentWord = doc_body?.querySelector(`#word-${currentWordIndex + 1}`)
            if (currentWord) {
                currentWord.classList.add('highlight');
                currentWord.style.background = "yellow"
            }
        }
    }, [currentWordIndex]);

    const handleBookLoaded = async (book) => {
        if (viewerRef.current) {
            const iframeDocument =await viewerRef.current.querySelector('iframe')?.contentWindow?.document
            // Find the image element within the iframe content
            console.log(isLoaded,iframeDocument)
            if(iframeDocument){
                let body = iframeDocument?.querySelector("body");
                let para = iframeDocument?.querySelectorAll("p");
                var image = iframeDocument?.querySelector("p img") || iframeDocument?.querySelector('img');
                var span = iframeDocument?.querySelectorAll("p span");
                var image_container = iframeDocument?.querySelector(".image");

                const styleElement = iframeDocument?.createElement("style");
                styleElement.type = "text/css";

                // Add your custom styles for images
                styleElement.textContent = `
                    html{
                        margin: 0 !important;
                    }
                    img {
                      max-width: 500px !important;
                      max-height: 275px !important;
                      width:100% !important;
                      margin-top:1rem;
                    };

                    @media (max-width:650px){
                        img{
                            margin-top:1rem;
                            width: 100% !important;
                        }
                    }

                    svg{
                        width:100%;
                        max-width:100% !important;
                        max-height:100% !important;
                        margin-top:2rem;
                    }

                    @media(max-width:650px){
                        svg{
                            margin-top:1rem !important;
                        }
                    }
                `;
                // Append the style element to the content's head
                iframeDocument.head.appendChild(styleElement);

                if (image) {
                    image.style.maxWidth = "500px !important";
                    image.style.maxHeight = "275px !important";
                }
                if (body) {
                    body.style.display = "flex";
                    body.style.flexDirection = "column";
                    body.style.justifyContent = "center";
                    body.style.height = '100%'
                    // body.style.justifyContent = "flex-start";
                    // body.style.gap = "1rem";
                    body.style.alignItems = "center";
                }
                if (para) {
                    para.forEach((par) => {
                        par.style.margin = "0";
                        par.style.fontWeight = '500'
                        par.style.maxWidth = window.innerWidth > 650 ? "70%" : "100%"
                        par.style.margin = "0 auto"
                    })
                    if (!image && source !== 'ReadandSpeak') {
                        para.forEach((par) => {
                            par.style.margin = "0";
                            par.style.maxWidth = "100%"
                        })
                        if (para[0]) para[0].style.marginTop = '1rem'
                    }
                }
                if (bookLoadedFunc) bookLoadedFunc()
            }
        };
    };

    const openSyllablesModal = () => {
        if (syllables?.length > 0) {
            setShowTooltip(false)
            setOpenShowSyllablesModal(true)
            renditionRef.current.annotations.remove(
                selections?.cfiRange,
                'highlight',
            )
        }
    }

    const openMeaningsModal = () => {
        // if (loadingMeanings) {
        setShowTooltip(false)
        setOpenShowMeaningModal(true)
        renditionRef.current.annotations.remove(
            selections?.cfiRange,
            'highlight',
        )
        // }
    }

    const readAloud = (text) => {
        setShowTooltip(false)
        // startReading(text)
        if (!isLoading) {
            audioRef.current.play()
            setIsAudioLoading(false)
        } else {
            setIsAudioLoading(true)
        }
        renditionRef.current.annotations.remove(
            selections?.cfiRange,
            'highlight',
        )
    }

    const handlePrev = async () => {
        setShowTooltip(false);
        setIsReadAloud(false)
        setIsPaused(false)
        setIsAudioLoading(false)
        setFontSize(source === 'ReadandSpeak' ? 1 : 1.2)
        await handlePrevFunc()
        readAloudAudioRef.current.pause()
        readAloudAudioRef.current.currentTime = 0
        setTextNodes()
    }

    const handleNext = async () => {
        setShowTooltip(false);
        setIsReadAloud(false)
        setIsPaused(false)
        setIsAudioLoading(false)
        setFontSize(source === 'ReadandSpeak' ? 1 : 1.2)
        await handleNextFunc()
        readAloudAudioRef.current.pause()
        readAloudAudioRef.current.currentTime = 0
        setTextNodes()
        if (source === "ReadandSpeak" && renditionRef?.current?.location?.start?.index === 4) {
            setVisible(true)
        }
    }

    const handleFontIn = () => {
        if (renditionRef.current) {
            setFontSize((prevSize) => {
                const newSize = prevSize + 0.2;
                renditionRef.current.themes.fontSize(`${newSize}rem`);
                return newSize;
            });
        }
    };

    const handleFontOut = () => {
        if (renditionRef.current) {
            setFontSize((prevSize) => {
                const newSize = prevSize - 0.2;
                renditionRef.current.themes.fontSize(`${newSize}rem`);
                return newSize;
            });
        }
    };

    const startReadAloud = () => {
        setIsReadAloud(true)
        renditionRef.current.annotations.remove(selections.cfiRange, "highlight");
        if (dataStory && sendStoryAnalytics) {
            sendStoryAnalytics(EVENT.READ_ALOUD_STARTED, dataStory, null, {
                [EVENT.CURRENT_PAGE_NO]: currentPageIndex,
            })
        }
        if (!isPaused) {
            const doc = viewerRef.current.querySelector('iframe').contentWindow.document
            const doc_body = doc?.body
            let image = doc.querySelector("p img") || doc.querySelector("img");

            let textHtml = `<div style="display:flex;align-items:center;${source !== 'ReadandSpeak' ? "gap:1rem;" : ""}flex-direction:column;height:100%;width:100%;">`
            let html = `<div style="display:flex;align-items:center;justify-content:center;
            ${image ?
                    window.innerWidth > 650 ? 'width:70%;' : 'width:100%;'
                    : source !== 'ReadandSpeak' ? "margin-top:1rem;" : ""}
                 ${source !== "ReadandSpeak" ? "gap:1rem;" : ""}
                 flex-direction:column;">`
            if (textNodesRef.current.length > 0) {
                let prevInd = 0
                textNodesRef.current.forEach((node, ind) => {

                    const content = node.type === 'text' || node.type === 'heading' || node.type === 'subheading' ? node.content : null
                    const src = node.type === 'image' ? node.src : null
                    if (node.type === 'text' || node.type === 'heading' || node.type === 'subheading') {
                        if (content) {
                            const words = content.split(/\s+|—/);
                            let paraText = `<p style="margin:0">`
                            let wrappedText = ''
                            if (node.type === 'heading' || node.type === 'subheading') {
                                // wrappedText = words.map((word, index) => `<strong><span style="font-weight=700;" id="word-${ind + 1}${index + 1}">${word} </span></strong>`).join('');
                                wrappedText = words.map((word, index) => { prevInd++; return (`<strong><span style="font-weight=700;" id="word-${prevInd}">${word} </span></strong>`) }).join("");
                            } else {
                                // wrappedText = words.map((word, index) => `<span id="word-${ind + 1}${index + 1}" style="font-weight=550">${word} </span>`).join('');
                                wrappedText = words.map((word, index) => { prevInd++; return (`<span id="word-${prevInd}" style="font-weight=550">${word} </span>`) }).join("");
                            }
                            paraText = paraText.concat(wrappedText)
                            paraText = paraText.concat('</p>')
                            // textHtml = textHtml.concat(paraText)
                            html = html.concat(paraText)
                            readAloudFunc()
                        }
                    }
                    else {
                        // console.log('inside else',src?.substring(0,src?.length))
                        textHtml = textHtml.concat(`<img src="${src}" style="max-height:275px !important; max-width:500px !important;width:100%"/>`)
                    }
                })
                html = html.concat('</div>')
                textHtml = textHtml.concat(html)
                textHtml = textHtml.concat('</div>')
                doc_body.innerHTML = textHtml;
            }

            doc_body.style.display = "flex"
            doc_body.style.gap = '8px'
        } else {
            readAloudFunc()
        }
    };

    const stopReadAloud = () => {
        setIsReadAloud(false)
        setIsPaused(true)
        readAloudAudioRef.current.pause()
        if (dataStory && sendStoryAnalytics) {
            sendStoryAnalytics(EVENT.READ_ALOUD_STOPPED, dataStory, null, {
                [EVENT.CURRENT_PAGE_NO]: currentPageIndex,
            })
        }
    }

    const setTextNodes = () => {
        if (viewerRef.current) {
            const doc_body = viewerRef.current.querySelector('iframe')?.contentWindow?.document
            let textNodes = getTextNodes(doc_body);
            textNodesRef.current = textNodes
            // getTTS()
        }
    }

    const updatePlaybackRate = (value) => {
        const newPlaybackRate = parseFloat(value);
        setPlayBackRate(newPlaybackRate);
        readAloudAudioRef.current.playbackRate = value
        // if (source === 'ReadandSpeak') parseHTMLText(HTMLString, value)
        stopReadAloud()
    };

    const sliderOverlay = (
        <div
            className="playback-slider-container"
            onClick={(e) => e.stopPropagation()}
        >
            <Slider
                vertical
                min={0.4}
                max={2}
                step={0.2}
                value={playbackRate}
                onChange={updatePlaybackRate}
                className="playback-slider"
            />
        </div>
    );

    const defaultVoices = [
        {
            'name': "English Indian Female",
            "value": "en-IN-NeerjaNeural"
        },
        {
            'name': "English Indian Male",
            "value": "en-IN-PrabhatNeural"
        },
        {
            'name': "English US Female",
            "value": "en-US-AvaNeural"
        },
        // {
        //     'name': "English US Male 1",
        //     "value": "en-US-AndrewNeural"
        // },
        // {
        //     'name': "English US Female 2 ",
        //     "value": "en-US-JennyNeural"
        // },
        {
            'name': "English US Male",
            "value": "en-US-BrianNeural"
        },
        {
            'name': "English UK Female",
            "value": "en-GB-SoniaNeural"
        },
        {
            'name': "English UK Male",
            "value": "en-GB-RyanNeural"
        },
    ]

    const selectVoicesOverlay = (
        <Menu>
            {
                defaultVoices?.map((voice) => (
                    <Menu.Item onClick={() => {
                        setIsReadAloud(false)
                        handleSelectedVoice(voice.value)
                        setIsSelectVoicesDropdownOpen(false)
                        setIsPaused(true)
                    }} className={`select_voice_option ${voice.value === selectedVoice && "active"}`} key={voice.value}>
                        <p>
                            {voice.name}</p>
                    </Menu.Item>

                ))
            }
        </Menu>
    )
    return (
        <>
            <div className='epub_container'>
                <audio
                    ref={audioRef}
                    controls
                    onTimeUpdate={handleTimeUpdate}
                    style={{ display: "none" }}
                ></audio>
                {
                    (window.innerWidth > 650 && (source === 'ReadandSpeak' ? !showingLoader : true)) && showButtons ?
                        <EpubButton handleOnClick={handlePrev} isDisabled={currentPageIndex === 0} src={LeftArrowIcon} alt="<" btnClass={"left_arrow_btn"} />
                        :
                        <div className="epub_button_container" />
                }
                <div style={{ height: '100%', display: 'flex', flexDirection: 'column', width: '80%' }} id="epub-render" onBlur={() => {
                    renditionRef.current.annotations.remove(
                        selections?.cfiRange,
                        'highlight',
                    ); setShowTooltip(false)
                }}>
                    <div style={{ flex: 1 }} ref={viewerRef}>
                        <ReactReader
                            url={url}
                            location={location}
                            locationChanged={(rendition) => onLocationChanged(rendition)}
                            showToc={false}
                            onReady={(book) => {
                                // console.log("logged")
                                // handleBookLoaded(book);
                                // setIsLoaded(true)
                            }}
                            getRendition={(rendition) => {
                                renditionRef.current = rendition;
                                // renditionRef.current.display(0)
                                renditionRef.current.themes.default({
                                    body: { 'overflow-y': 'scroll', 'height': '100%' },
                                    '::selection': {
                                        background: '#c899eb'
                                    }
                                });
                                renditionRef.current.flow('scrolled-doc');
                                renditionRef.current.themes.fontSize(`${fontSize}rem`);
                                setSelections([])
                                rendition.on("rendered", (book) => {
                                    handleBookLoaded(book);
                                    // setIsLoaded(true)
                                });
                                rendition.display().then(() => {
                                    console.log("heer")
                                    setIsLoaded(true)
                                    // handleBookLoaded()
                                })
                                // setIsLoaded(true)
                            }}
                            styles={ownStyles}
                        />

                        {showTooltip && (
                            <div
                                className='epub_tooltip_container'
                                ref={tooltipRef}
                                style={{

                                    top: tooltipPosition.top,
                                    left: tooltipPosition.left,

                                }}
                            >
                                <div className='epub_tooltip_button' onClick={() => readAloud(selections?.text)}>🔊 Listen</div>
                                <div className='epub_tooltip_button' onClick={() => openMeaningsModal()}>📖 Meaning</div>
                                <div className='epub_tooltip_button'
                                    onClick={() => openSyllablesModal()}
                                >
                                    ✂ Syllables
                                </div>
                            </div>
                        )}
                    </div>
                </div>

                {
                    (window.innerWidth > 650 && (source === 'ReadandSpeak' ? !showingLoader : true)) && !(lastPage || currentPageIndex === tocLength - 1) ?
                        <EpubButton handleOnClick={handleNext} isDisabled={lastPage || currentPageIndex === tocLength - 1 || !isLoaded} src={RightArrowIcon} alt=">" btnClass={"right_arrow_btn"} />
                        :
                        <div className="epub_button_container" />

                }
            </div>

            {
                showButtons && isLoaded && (source === "ReadandSpeak" ? !showingLoader : true) &&
                <div className="epub_buttons_container">

                    {/* {showStartButton ? window.innerWidth > 650 ? ( */}
                    {/* <div className="read_along_read_aloud_btns_containe"> */}
                    {
                        window.innerWidth <= 650 && showButtons &&
                        // <button className='pdf_control_btn' onClick={() => { setShowTooltip(false); handlePrevFunc() }} disabled={currentPageIndex === 0}><FaChevronLeft /></button>
                        <EpubButton handleOnClick={handlePrev} isDisabled={currentPageIndex === 0} src={LeftArrowIcon} alt="<" />
                    }
                    <EpubButton handleOnClick={handleFontIn} isDisabled={fontSize >= 2.5} src={ZoomInIcon} alt="+" text={'Zoom in'} />
                    <EpubButton handleOnClick={handleFontOut} isDisabled={fontSize <= 1} src={ZoomoutIcon} alt="-" text={'Zoom out'} />
                    {source === "ReadandSpeak" ?
                        isRecording ?
                            <EpubButton handleOnClick={stopRecording} src={StopRecordIcon} alt="stop" text={'Stop Recording'} main={true} btnClass={"stop_recording_btn"} />
                            :
                            <Tooltip title="Tap the record button to practice" visible={visible}>
                                <EpubButton handleOnClick={startRecording} src={RecordIcon} alt="record" text={'Record'} main={true} />
                            </Tooltip>
                        :
                        isLoadingAudio ?
                            <EpubButton alt="listen" text={'Listen'} main={true} />
                            :
                            isReadAloud ?
                                <EpubButton handleOnClick={stopReadAloud} isDisabled={!isReadAloud} src={PauseAudioIcon} alt="pause" text={'Pause'} main={true} />
                                :
                                <EpubButton handleOnClick={startReadAloud} isDisabled={isReadAloud} src={ListenIcon} alt="listen" text={'Listen'} main={true} />
                    }
                    {
                        source === 'ReadandSpeak' ?
                            isReadAloud ?
                                <EpubButton handleOnClick={stopReadAloud} isDisabled={!isReadAloud || isRecording} src={PauseAudioIcon} alt="pause" text={'Pause'} />
                                :
                                <EpubButton handleOnClick={startReadAloud} isDisabled={isReadAloud || isRecording} src={ListenIcon} alt="listen" text={'Listen'} />
                            :
                            <Dropdown overlay={selectVoicesOverlay} trigger={['click']} placement="topCenter" visible={isSelectVoicesDropdownOpen}
                                onVisibleChange={(flag) => setIsSelectVoicesDropdownOpen(flag)} className="select_voice_dropdown">
                                <EpubButton handleOnClick={() => setIsSelectVoicesDropdownOpen(!isSelectVoicesDropdownOpen)} src={SelectVoiceIcon} alt="voice" text={'Select Voice'} btnClass={"select_voice_btn"} />
                            </Dropdown>
                    }
                    <Dropdown overlay={sliderOverlay} trigger={['click']} placement="topCenter" visible={isSliderOpen}
                        onVisibleChange={(flag) => setOpenSlider(flag)}>
                        <EpubButton handleOnClick={() => setOpenSlider(!isSliderOpen)} src={PlaybackRateIcon} alt="rate" text={'Audio speed'} />
                    </Dropdown>

                    {
                        window.innerWidth <= 650 && !(lastPage || currentPageIndex === tocLength - 1) &&
                        // <button className='pdf_control_btn' onClick={() => { setShowTooltip(false); handleNextFunc() }} disabled={lastPage || currentPageIndex === tocLength - 1 || !isLoaded}><FaChevronRight /></button>
                        <EpubButton handleOnClick={handleNext} isDisabled={lastPage || currentPageIndex === tocLength - 1 || !isLoaded} src={RightArrowIcon} alt=">" />

                    }
                    {
                        (lastPage || currentPageIndex === tocLength - 1) && <Button type="primary"
                            className="last_page_exit_btn"
                            onClick={finishStory}
                        >
                            Finish Story
                        </Button>
                    }

                </div>
            }

            {
                !showButtons && window.innerWidth <= 650 &&
                <div className="epub_buttons_container" >
                    <EpubButton handleOnClick={handlePrev} isDisabled={currentPageIndex === 0} src={LeftArrowIcon} alt="<" />
                    <EpubButton handleOnClick={handleNext} isDisabled={lastPage || currentPageIndex === tocLength - 1 || !isLoaded} src={RightArrowIcon} alt=">" />

                </div>
            }

            {
                <Modal
                    centered
                    width="70%"
                    height="70%"
                    className=""
                    visible={openShowMeaningModal}
                    onCancel={() => setOpenShowMeaningModal(false)}
                    footer={null}
                    key={1}
                    destroyOnClose
                >
                    <MeaningModal data={meaningData && meaningData} loading={loadingMeanings} word={selections?.text} closeModal={closeMeaningModal} />

                </Modal>
            }
            {
                <Modal
                    centered
                    width="70%"
                    height="70%"
                    className=""
                    visible={openShowSyllablesModal}
                    onCancel={() => setOpenShowSyllablesModal(false)}
                    footer={null}
                    key={2}
                    destroyOnClose
                >
                    <SyllablesModal word={selections?.text} data={syllables && syllables} closeModal={closeSyllablesModal} />

                </Modal>
            }

        </>
    )
}

export default React.memo(connect(
    ({ detailedStory }) => ({ ...detailedStory }),
    (dispatch) => bindActionCreators({ ...storyActions }, dispatch)
)(EpubReader));