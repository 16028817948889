// ReadAlongLayout.js

import React, { useCallback, useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import "./ReadAloud.scss";
import BannerImage from "../../assets/image/bannerreadaloud.png";
import { Modal } from "antd";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as collectionActions from "store/collections/actions";
import * as storyActions from "store/story/actions";
import BookSummary from "components/bookSummary";
import * as EVENT from "../../utils/eventKeys";
import SuccessBackground from "assets/image/success-background.svg";
import SuccessModal from "components/SuccessModal";
import Storycard from "components/Storycard";
import Loader from "components/Loader/Loader";

const ReadAloudLayout = (props) => {
  const [storyData, setStoryData] = useState({});
  const [offset, setOffset] = useState(0);
  const [stories, setStories] = useState(null);
  const [openBookSummaryModal, setOpenBookSummaryModal] = useState(false);
  const [activeStoryQuestion, setActiveStoryQuestion] = useState({});
  const [successModalOpened, setSuccessModalOpened] = useState(false);
  const [successModalData, setSuccessModalData] = useState({});
  const [loading, setLoading] = useState(false);

  const openModal = async (storyId) => {
    setLoading(true);
    const res = await props.getStoryDetail(storyId);
    setStoryData(res);
    if (res.has_mcq) {
      const questionData = await props.getMcqRequest(storyId);
      setActiveStoryQuestion(questionData);
    }
    setLoading(false);
    setOpenBookSummaryModal(true);
  };

  const closeModal = () => {
    setOpenBookSummaryModal(false);
    setStoryData({});
    getReadAloudCollectionData(offset);
  };

  const getReadAloudCollectionData = async (offsetData) => {
    setLoading(true);
    const res = await props.getCollectionsById(
      "73bee548-dcae-4587-9398-ce2f2a624589",
      offsetData
    );

    setStories(res);
    setLoading(false);
  };
  useEffect(() => {
    getReadAloudCollectionData(0);
  }, []);

  const bookmark = useCallback(async (id) => {
    setStories((prev) => {
      return prev.map((story) => {
        if (story.id === id) {
          return {
            ...story,
            is_liked: !story.is_liked,
          };
        }
        return story;
      });
    });
  }, []);

  const openSuccessModal = (data) => {
    setSuccessModalOpened(true);
    setOpenBookSummaryModal(false);
    setSuccessModalData(data);
  };

  const handleNextClick = () => {
    getReadAloudCollectionData(offset + 1);
    setOffset(offset + 1);
  };

  const handleBackClick = () => {
    const updatedOffset = offset > 0 ? offset - 1 : offset;
    getReadAloudCollectionData(updatedOffset);
    setOffset(updatedOffset);
  };

  return (
    <div className="read-aloud-layout">
      <div className="banner">
        <img src={BannerImage} alt="Banner" className="banner-img" />
      </div>
      {loading ? (
        <div className="content-container">
          <Loader />
        </div>
      ) : (
        <div className="content-container">
          {stories?.length > 0 && !loading ? (
            <div className="grid">
              {stories &&
                stories.map((story) => (
                  <div
                    style={{ marginBottom: "24px" }}
                    onClick={() => openModal(story.id)}
                    key={story.id}
                  >
                    <Storycard
                      key={story.id}
                      data={story}
                      bookmark={bookmark}
                    />
                  </div>
                ))}
            </div>
          ) : (
            <p className="no_data_title">No Stories available at the moment.</p>
          )}
          {stories?.length > 0 && (
            <div className="pagination">
              <div>
                <button
                  type="submit"
                  className="ant-btn ant-btn-primary"
                  onClick={() => handleBackClick()}
                  disabled={offset <= 0}
                >
                  Back
                </button>
              </div>
              <div>
                <button
                  type="submit"
                  className="ant-btn ant-btn-primary"
                  onClick={() => handleNextClick()}
                  disabled={!props.collectionsRes?.has_next}
                >
                  Next
                </button>
              </div>
            </div>
          )}
        </div>
      )}
      <Modal
        title="Book Summary"
        centered
        className="story-modal"
        visible={openBookSummaryModal}
        onCancel={() => closeModal()}
        footer={null}
        key={1}
        destroyOnClose
      >
        <BookSummary
          source={EVENT.SOURCE_FEED_PAGE}
          data={storyData}
          questions={activeStoryQuestion}
          bookmark={bookmark}
          openSuccessModal={openSuccessModal}
          close={() => closeModal()}
          fromReadAloud={true}
        />
      </Modal>
      <Modal
        visible={successModalOpened}
        className="success-modal"
        footer={null}
        key={2}
        maskStyle={{
          backgroundColor: "rgba(0, 0, 0, 0.9)",
          backgroundImage: `url(${SuccessBackground})`,
        }}
        destroyOnClose
      >
        <SuccessModal
          data={successModalData}
          currentStoryData={storyData}
          successModalGoBack={() => props.history.push("/")}
          closeSuccess={(id) => {
            setSuccessModalData({});
            setSuccessModalOpened(false);
            if (id) {
              this.handleOpenModal(id);
            }
          }}
        />
      </Modal>
    </div>
  );
};

export default withRouter(
  connect(
    ({ collections }) => ({ ...collections }),
    (dispatch) =>
      bindActionCreators({ ...collectionActions, ...storyActions }, dispatch)
  )(ReadAloudLayout)
);
