import React, { useEffect, useRef, useState } from "react";
import { synthesize, synthesizeStream } from "@echristian/edge-tts";
import { storage as LocalStorage } from "services/config/storage";

const useTTSComponent = (audioRef) => {
  const [subtitleData, setSubtitleData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [currentWordIndex, setCurrentWordIndex] = useState(-1);
  const [audioUrl, setAudioUrl] = useState("");
  const latestRequestRef = useRef(0);

  const defaultVoice = "en-IN-NeerjaNeural";

  // Generate audio and subtitles
  const generateTTS = async (text, rate, voice) => {
    const requestId = ++latestRequestRef.current;
    setIsLoading(true);
    let grade = LocalStorage?.fetch.defaultChild()?.grade?.name?.split(" ");
    let defaultRate = grade[1]
      ? grade[1] >= 6
        ? 1
        : grade[1] >= 3 && grade[1] < 6
        ? 0.9
        : grade[1] >= 1 && grade[1] < 3
        ? 0.85
        : 0.75
      : 0.75;
    const result = await synthesize({
      text,
      voice: voice || defaultVoice,
      format: "audio-16khz-32kbitrate-mono-mp3",
      subtitle: { wordsPerCue: 1 },
      rate: rate || defaultRate,
    });
    // console.log(requestId,latestRequestRef.current)
    if (requestId !== latestRequestRef.current) return;

    const { audio, subtitle } = result;
    console.log(audio, subtitle, isLoading);

    let cleanedSubtitleArray = subtitle.filter(
      (sub) => sub.text.match(/^(?![^\w\s'"]$).+$/g) // Removing all non-alphanumeric characters
    );
    // console.log(cleanedSubtitleArray);
    const subtitleArray = cleanedSubtitleArray;

    setSubtitleData(subtitleArray);

    // Set up audio playback
    const audioBlob = new Blob([audio], { type: "audio/mp3" });
    const audioUrl = URL.createObjectURL(audioBlob);
    setAudioUrl(audioUrl);
    audioRef.current.src = audioUrl;
    setIsLoading(false);
  };

  // Sync text highlighting with audio
  const handleTimeUpdate = () => {
    if (!audioRef.current || subtitleData.length === 0) return;

    const currentTime = audioRef.current.currentTime * 1000; // Convert to ms

    // Find the current word based on time
    const currentIndex = subtitleData.findIndex(
      (word) => currentTime >= word.start && currentTime < word.end
    );

    if (currentIndex !== currentWordIndex) {
      setCurrentWordIndex(currentIndex);
    }
  };

  return {
    handleTimeUpdate,
    generateTTS,
    currentWordIndex,
    subtitleData,
    isLoading,
    audioUrl,
  };
};

export default useTTSComponent;

