import React, { useState, useEffect } from "react";
import "./index.scss";
import assignmentChild from "../../../../../assets/image/assignment-child.png";
import { Switch, Spin } from "antd";
import Modal from "react-modal";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import StudentsModal from "../../StudentsModal";

function AssignmentDetails(props) {
  const [loading, setLoading] = useState(false);
  const [offset, setOffset] = useState(0);
  const [has_next, setHas_next] = useState(false);
  const [listData, setListData] = useState([]);
  const [showStudentsModal, setShowStudentsModal] = useState({
    value: false,
    type: "",
    data: [],
  });

  const checkOverdue = (date) => {
    const currentDate = new Date();
    const dueDate = new Date(date);

    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth();
    const currentDay = currentDate.getDate();

    const dueYear = dueDate.getFullYear();
    const dueMonth = dueDate.getMonth();
    const dueDay = dueDate.getDate();

    if (
      currentYear > dueYear ||
      (currentYear === dueYear && currentMonth > dueMonth) ||
      (currentYear === dueYear &&
        currentMonth === dueMonth &&
        currentDay > dueDay)
    ) {
      return true;
    }
    return false;
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate();
    let daySuffix;

    if (day === 1 || day === 21 || day === 31) {
      daySuffix = "st";
    } else if (day === 2 || day === 22) {
      daySuffix = "nd";
    } else if (day === 3 || day === 23) {
      daySuffix = "rd";
    } else {
      daySuffix = "th";
    }

    const options = { day: "numeric", month: "long" };
    const formattedDate = date.toLocaleDateString(undefined, options);
    const formattedDay = `${day}${daySuffix}`;
    return formattedDate.replace(String(day), formattedDay);
  };

  const handleOpenAssignmentContent = (assignment) => {
    if (assignment && assignment.tasks.length > 0) {
      if (assignment.tasks[0].content_type === "activity") {
        props.history.push(`/activity/${assignment.tasks[0].content_id}`);
      } else if (assignment.tasks[0].content_type === "story") {
        props.history.push(`/story/${assignment.tasks[0].content_id}`);
      } else if (assignment.tasks[0].content_type === "quiz") {
        props.history.push(`/quiz/${assignment.tasks[0].content_id}`);
      } else if (assignment.tasks[0].content_type === "news") {
        props.history.push(`/news/${assignment.tasks[0].content_id}`);
      }
    }
  };

  const handleNotStarted = (type, data, e) => {
    e.preventDefault();
    e.stopPropagation();
    setShowStudentsModal({
      value: true,
      type,
      data,
    });
  };

  useEffect(() => {
    if (props.assignmentsRes) {
      setOffset(props.assignmentsRes.next_page);
      setHas_next(props.assignmentsRes.has_next);
      setLoading(false);

      if (listData?.length == 0) {
        setListData(props.assignmentsRes.result);
      } else {
        setListData([...new Set([...listData, ...props.assignmentsRes.result])]);
      }
    }
  }, [props.assignmentsRes.offset]);

  useEffect(() => {
    if (offset !== 0 && loading) {
      props.fetchAllAssignments(offset);
    } else {
      setLoading(false);
    }
  }, [loading]);

  return (
    <>
      {!props.allAssignments && (
        <div className="no-assignments">Loading...</div>
      )}
      {props.allAssignments && props.allAssignments?.length === 0 ? (
        <div className="no-assignments">
          There are no assignments in place for students at the moment. Let's
          create some to foster student engagement.
        </div>
      )
      :
      <div
        className="assignments_container"
        onScroll={(event) => props.Scroll_Result(event, setLoading, has_next)}
      >
        { props.allAssignments && listData && listData?.map((assignment) => (
            <div
              className="assignment_new"
              key={assignment.id}
              onClick={() => handleOpenAssignmentContent(assignment)}
              // onClick={() => openAssignmentModal(assignment)}
            >
              <div
                className="first-row d-flex flex-row-reverse"
                style={{ marginTop: "10px" }}
              >
                <div className="assignment-tags d-flex ">
                  <div
                    className={
                      assignment.tasks[0].content_type === "story"
                        ? "type story"
                        : assignment.tasks[0].content_type === "activity"
                        ? "type activity"
                        : assignment.tasks[0].content_type === "news"
                        ? "type ntype"
                        : assignment.tasks[0].content_type === "quiz"
                        ? "type quiz-type"
                        : assignment.tasks[0].content_type === "guidedpath-unit"
                        ? "type gp-unit"
                        : null
                    }
                  >
                    <span className="text">
                      {assignment.tasks[0].content_type === "guidedpath-unit"
                        ? "GP Unit"
                        : assignment.tasks[0].content_type}
                    </span>
                  </div>
                  <div className="ml-2"></div>
                  <Switch
                    size="medium"
                    checked={assignment.is_deleted ? false : true}
                    onChange={(checked, e) =>
                      props.deactivateAssignment(assignment, e)
                    }
                  />
                </div>
              </div>
              <div
                className="second-row d-flex flex"
                style={{ marginTop: "14px", marginRight: "15px" }}
              >
                <div className="assignment-dates col-4">
                  <div
                    className="assigned-date"
                    // style={{ background: "#842C86" }}
                  >
                    Assigned Date
                    <br />
                    <span style={{ fontSize: "10px" }}>
                      {formatDate(new Date(assignment.created_at))}
                    </span>
                  </div>
                  <br />
                  <div className="due-date"
                  // style={{ background: "#842C86" }}
                  >
                    <span>Due Date</span> <br />
                    <span style={{ fontSize: "10px" }}>
                      {formatDate(new Date(assignment.deadline))}
                    </span>
                  </div>
                  <div
                    className="due-date"
                    style={{
                      marginTop: "25px",
                      height: "2rem",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      // background: "#842C86",
                    }}
                  >
                    <span style={{ display: "block", textAlign: "center" }}>
                      Progress
                    </span>
                  </div>
                </div>
                <div className="bar"></div>

                <div className="assignment-details col-8">
                  <div className="name">
                    <span>{assignment.name}</span>
                  </div>
                  <div className="description">
                    <span>{assignment.description}</span>
                  </div>

                  {!checkOverdue(assignment.deadline) ? (
                    <div className="assignment_status_container">
                      <div
                        className="assigned-date"
                        style={{
                          backgroundColor: "#D72D2D",
                          width: "100%",
                          marginRight: "2%",
                          padding: "8px",
                          boxShadow: " 0 3px 10px rgb(0 0 0 / 0.2)",
                          cursor: "pointer",
                        }}
                        onClick={(e) =>
                          handleNotStarted(
                            "Not Started",
                            assignment.child_stats?.not_started_children,
                            e
                          )
                        }
                      >
                        {assignment.child_stats?.not_started || 0}
                        <br />
                        <span
                          style={{ fontSize: "10px", borderRadius: "10px" }}
                        >
                          NOT STARTED
                        </span>
                      </div>
                      {/* <br /> */}
                      <div
                        className="due-date"
                        style={{
                          width: "100%",
                          marginRight: "2%",
                          background: "#FC9D45",
                          padding: "8px",
                          boxShadow: " 0 3px 10px rgb(0 0 0 / 0.2)",
                          cursor: "pointer",
                        }}
                        onClick={(e) =>
                          handleNotStarted(
                            "In Progress",
                            assignment.child_stats.in_progress_children,
                            e
                          )
                        }
                      >
                        <span>{assignment.child_stats?.in_progress || 0}</span>{" "}
                        <br />
                        <span style={{ fontSize: "10px" }}>IN PROGRESS</span>
                      </div>
                      <div
                        className="due-date"
                        style={{
                          backgroundColor: "#7DCF3C",
                          width: "100%",
                          marginRight: "2%",
                          padding: "8px",
                          boxShadow: " 0 3px 10px rgb(0 0 0 / 0.2)",
                          cursor: "pointer",
                        }}
                        onClick={(e) =>
                          handleNotStarted(
                            "Completed",
                            assignment.child_stats.completed_children,
                            e
                          )
                        }
                      >
                        <span style={{ display: "block", textAlign: "center" }}>
                          {assignment.child_stats?.completed || 0}
                        </span>
                        <span style={{ fontSize: "10px" }}>COMPLETED</span>
                      </div>
                    </div>
                  ) : (
                    <div className="assignment_status_container">
                      <div
                        className="assigned-date"
                        style={{
                          backgroundColor: "#D72D2D",
                          width: "100%",
                          marginRight: "2%",
                          padding: "8px",
                          boxShadow: " 0 3px 10px rgb(0 0 0 / 0.2)",
                          cursor: "pointer",
                        }}
                        onClick={(e) =>
                          handleNotStarted(
                            "Overdue",
                            [
                              ...assignment.child_stats.not_started_children,
                              ...assignment.child_stats.in_progress_children,
                            ],
                            e
                          )
                        }
                      >
                        {parseInt(assignment.child_stats.not_started) +
                          parseInt(assignment.child_stats.in_progress) || 0}
                        <br />
                        <span
                          style={{ fontSize: "10px", borderRadius: "10px" }}
                        >
                          OVERDUE
                        </span>
                      </div>
                      {/* <br /> */}
                      <div
                        className="due-date"
                        style={{
                          backgroundColor: "#7DCF3C",
                          width: "100%",
                          marginRight: "2%",
                          padding: "8px",
                          boxShadow: " 0 3px 10px rgb(0 0 0 / 0.2)",
                          cursor: "pointer",
                        }}
                        onClick={(e) =>
                          handleNotStarted(
                            "Completed",
                            assignment.child_stats.completed_children,
                            e
                          )
                        }
                      >
                        <span style={{ display: "block", textAlign: "center" }}>
                          {assignment.child_stats?.completed || 0}
                        </span>
                        <span style={{ fontSize: "10px" }}>COMPLETED</span>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          ))}

        {loading && (
          <div className="assignments_loading_container">
            Loading ...
            <Spin />
          </div>
        )}
      </div>
    }

      <StudentsModal
        visible={showStudentsModal.value}
        children={showStudentsModal.data}
        type={showStudentsModal.type}
        closeModal={() => {
          setShowStudentsModal({
            value: false,
            type: "",
            data: [],
          });
        }}
      />
    </>
  );
}

export default connect(({ appConfig }) => ({
  ...appConfig,
}))(withRouter(AssignmentDetails));
