/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import { Button, Form, Input, Select, TreeSelect, } from "antd";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import OtpInput from "react-otp-input";
import * as userActions from "store/user/actions";
import { getAndSetChild } from "../../store/user/actions";
import LoginImg from "assets/image/hello_login.png";
import { storage as LocalStorage } from "services/config/storage";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { Analytics } from "../../services/analytics";
import Header from "../../components/Header";
import * as EVENT from "../../utils/eventKeys";
import store from "../../store";
import loader from "../../assets/image/loader.gif";

//FREAD-1352
import "./index.scss";
//FREAD-1352

const { Option } = Select;
var jwt = require("jsonwebtoken");
var privateKey = "056c1e10b04954ccdae40b564ffcff4882892697044a6ede";

function hasErrors(fieldsError) {
  return Object.keys(fieldsError).some((field) => fieldsError[field]);
}

const otpBoxStyle = {
  // width: '100%',
  width: "45px",
  textAlign: "center",
  height: "45px",
  outline: "none",
  fontSize: "24px",
  border: "1px solid rgb(238, 238, 238)",
  borderRadius: "10px",
};
// const tribeUrl = "https://freadom.tribe.so/";
class TeacherSignup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      action: null,
      loading: false,
      email: "",
      password: "",
      code: "",
      phone: "",
      child: "",
      loginUsingOtp: false,
      otpSent: false,
      isSendingOtp: false,
      otp: "",
      inputFocused: false,
      grade: "",
      // time: {},
      // seconds: 30,
      showResend: false,
      disabledOtpButton: false,
      selectedGrade: null,
      error: null,
      verfied: false,
      enterChildName: false,
      otpRes: null,
      disabledChildButton: false,
      timer: 30,
      referrer: null,
      emailVerfied: false,
      emailOtpSent: false,
      emailRequired: false,
      emailOtp: null,
      disabledButton: false,
      isbuttonDisabled: false,
      is_teacher: true,
      SchoolCodeSelected: false,
      schoolCodeVerified: false,
      school: null,
      verifingSchool: false,
      childState: null,
      enteredName: false,
      schoolCodeYesNoPage: false,
      gradeSelected: false,
      schoolCodeVerifiedComplete: false,
      treeGrades: null,
      statesData: this.getStatesAndUnionTerritories(),
      selectedSections: [],
      gradeSections : [],
      childSection: null,
      loading: false,
      isLoading:false,
      accountExists: true,
      accountFound: false,

    };
    // this.timer = 0;
    this.eventList = [];
  }

  componentDidMount() {
    if (LocalStorage.fetch.authToken()) {
      if (LocalStorage.fetch.parentData().child_count > 0) {
        this.props.history.replace("/");
      }
    } else {
      LocalStorage.destroy.all();
      store.dispatch({ type: "RESET_STATE" });
    }
    this.eventList.push({ name: EVENT.ONBOARDING_GETSTARTED, data: null });
    this.props.getGrades();
    var referrer = document.referrer;
    if (referrer === "https://freadom.tribe.so/") {
      this.setState({ referrer: referrer });
    }
    this.checkIsTeacherFromURL();
  }
  componentWillUnmount() {
    clearInterval(this.clockCall);
  }

  checkIsTeacherFromURL = () => {
    console.log("checkIsTeacherFromURL");
    const url = window.location.href;

    // Parse the URL using URL object
    const parsedUrl = new URL(url);

    // Get the query string (part after ?)
    const queryString = parsedUrl.search;

    console.log("queryString:", queryString);
    const params = {};

    // Split the query string into key-value pairs
    queryString.split('&').forEach(pair => {
      const [key, value] = pair.split('=');
      params[key] = value;
    });

  }
  sendAnalytics = (event, data) => {
    let payload = {};
    payload[EVENT.DEVICE_ID] = localStorage.getItem("device_id")
      ? localStorage.getItem("device_id")
      : "";

    if (data) {
      payload = Object.assign({}, payload, data);
    }
    Analytics.logEvents(event, payload);
  };

  sendOtp = (e) => {
    e.preventDefault();

    // Analytics.logEvents(EVENT.ONBOARDING_PHONE_REGISTER)
    if (this.state.phone && this.state.phone !== "") {
      this.eventList.push({
        name: EVENT.ONBOARDING_PHONE_REGISTER,
        data: { [EVENT.PHONE_NUMBER]: this.state.phone },
      });

      // this.timer =0
      this.props.form.validateFields(async (err, values) => {
        if (!err) {
          this.setState({ isSendingOtp: true, showResend: false, timer: 30 });
          const otpObj = {
            country_code: this.state.code,
            contact_no: this.state.phone,
            user_type: "parent",
          };
          this.startTimer();
          const res = await this.props.triggerOtpSend(otpObj);
          if (true || res?.success) {
            this.setState({
              otpSent: true,
              verfied: false,
              isSendingOtp: false,
            });
          } else if (
            res?.data?.error &&
            res?.data?.error.error_code === 40001
          ) {
            this.setState({
              error: "This contact no is already used, Please login.",
            });
            this.setState({ isSendingOtp: false });
          } else {
            this.setState({ isSendingOtp: false });
          }
        }
      });
    } else {
      this.setState({ error: "Please enter phone number" });
    }
  };

  callOtp = (e) => {
    e.preventDefault();
    this.props.form.validateFields(async (err, values) => {
      this.eventList.push({
        name: EVENT.ONBOARDING_PHONE_REGISTER,
        data: { [EVENT.PHONE_NUMBER]: this.state.phone },
      });
      if (!err) {
        this.setState({ isSendingOtp: true, showResend: false, timer: 30 });
        const otpObj = {
          country_code: this.state.code,
          contact_no: this.state.phone,
          user_type: "parent",
        };
        const res = await this.props.triggerCallOtpSend(otpObj);
        this.startTimer();
        if (res?.success) {
          this.setState({ otpSent: true, isSendingOtp: false });
        } else if (res?.data?.error && res?.data?.error.error_code === 40001) {
          this.setState({
            error: "This contact no is already used, Please login.",
          });
          this.setState({ isSendingOtp: false });
        } else {
          this.setState({ isSendingOtp: false });
        }
      }
    });
  };

  startTimer = () => {
    this.setState({ disabledOtpButton: true });
    this.clockCall = setInterval(() => {
      this.decrementClock();
    }, 1000);
  };
  decrementClock = () => {
    if (this.state.timer === 0) {
      this.setState({ showResend: true, disabledOtpButton: false });
      clearInterval(this.clockCall);
    }
    this.setState((prevstate) => ({ timer: prevstate.timer - 1 }));
  };

  eventLogList = async () => {
    
    Analytics.logNavigation({
      from: EVENT.SOURCE_SIGN_UP_PAGE,
      to: EVENT.SOURCE_FEED_PAGE,
    });
  };

  verifyOtp = async (e) => {
    e.preventDefault();
    this.setState({
      disableAddChild: true,
    });
    let device_id = this.state.code + this.state.phone;
    // LocalStorage.set.deviceId(device_id);
    localStorage.setItem("device_id", device_id);
    // Analytics.logEvents(EVENT.REGISTRATION_OTP)
    const otpVerifyObj = {
      country_code: this.state.code,
      contact_no: this.state.phone,
      otp: this.state.otp,
      // action: "login",
      user_type: "parent",
      device_type: "web",
      device_id: device_id,
      is_teacher: this.state.is_teacher,
    };
    console.log("device_id", device_id, LocalStorage.fetch.deviceId());

    const res = await this.props.verifyOtpRequest(otpVerifyObj);
    console.log("res", res);
    if (res && res.auth_token) {
      this.setState({
          error: "Your account already Exists, Please Login",
      })
      this.eventList.push({
        name: EVENT.REGISTRATION_OTP,
        data: { [EVENT.PHONE_NUMBER]: this.state.phone, [EVENT.STATUS]: true },
      });

    } else if (res && res.signup_token) {
      console.log("res inside else if ", res);
      this.eventList.push({
        name: EVENT.REGISTRATION_OTP,
        data: { [EVENT.PHONE_NUMBER]: this.state.phone, [EVENT.STATUS]: true },
      });
      // this.sendAnalytics(EVENT.REGISTRATION_OTP,{[EVENT.PHONE_NUMBER]: this.state.phone,[EVENT.STATUS]:true})
      this.setState({
        verfied: true,
        loading: false,
        otpRes: res,
      });
      this.setState({ SchoolCodeSelected: true, schoolCodeYesNoPage: true });
    } else {
      this.eventList.push({
        name: EVENT.REGISTRATION_OTP,
        data: { [EVENT.PHONE_NUMBER]: this.state.phone, [EVENT.STATUS]: false },
      });

      if (res?.error?.error_message === 'This number is registered as a student. Please log in using the student option on the previous screen. For further assistance, contact your school administrator.') {
        console.log("account found");
        this.setState({
          verfied: true,
          loading: false,
          otpRes: res,
        });
        this.setState({ SchoolCodeSelected: true, schoolCodeYesNoPage: true, accountFound:true });
      }
      // this.sendAnalytics(EVENT.REGISTRATION_OTP,{[EVENT.PHONE_NUMBER]: this.state.phone,[EVENT.STATUS]:false})
      this.setState({ loading: false });
      this.setState({
        isbuttonDisabled: false,
        error: res?.error?.error_detail?.contact_no,
      });
    }


  };

  signup = async (sd) => {
    this.setState({
      verfied: true,
      loading: false,
      otpRes: sd,
      action: "signup",
    });
  };

  nextSection = (e) => {
    e.preventDefault();
    console.log("nextSection");
    this.eventList.push({
      name: EVENT.ONBOARDING_SELECT_GRADE,
      data: { [EVENT.GRADE]: this.state.grade },
    });
    console.log("form:", this.props.form);
    // this.sendAnalytics(EVENT.ONBOARDING_SELECT_GRADE,{[EVENT.GRADE]: this.state.grade})
    this.props.form.validateFields(async (err, values) => {
      if (!err) {
        this.setState({ enterChildName: true });
      }
    });


  };

  getStatesAndUnionTerritories = () => {
    return [
      "Andaman and Nicobar Islands",
      "Andhra Pradesh",
      "Arunachal Pradesh",
      "Assam",
      "Bihar",
      "Chandigarh",
      "Chhattisgarh",
      "Dadra and Nagar Haveli and Daman and Diu",
      "Delhi",
      "Goa",
      "Gujarat",
      "Haryana",
      "Himachal Pradesh",
      "Jammu and Kashmir",
      "Jharkhand",
      "Karnataka",
      "Kerala",
      "Ladakh",
      "Lakshadweep",
      "Madhya Pradesh",
      "Maharashtra",
      "Manipur",
      "Meghalaya",
      "Mizoram",
      "Nagaland",
      "Odisha",
      "Puducherry",
      "Punjab",
      "Rajasthan",
      "Sikkim",
      "Tamil Nadu",
      "Telangana",
      "Tripura",
      "Uttar Pradesh",
      "Uttarakhand",
      "West Bengal"
    ];
  }

  validate(name) {
    let n = name.trim();
    const re = /^[a-zA-Z0-9_ .]*$/;
    return re.test(n);
  }

  submitChildData = async (e) => {
    this.setState({ isLoading: true });
    if(e)
      e.preventDefault();
    let checkName = null;
    let childName = null;
    if (this.state.child) {
      checkName = this.validate(this.state.child);
      childName = this.state.child.trim();
    }
    if (childName && childName !== "" && checkName) {
      let grades_array = [];
      // if this.state.grade is not a array then make it a array
      if (!Array.isArray(this.state.grade)) {
        grades_array.push(this.state.grade);
      } else {
        grades_array = this.state.grade;
      }
      console.log("grade selected:", this.state.gradeSelected);
      var child_grades = null
      if (this.state.grade) {
        child_grades = this.state.grade;
      } else {
        child_grades = this.state.selectedGrades;
      }


      const childData = {
        name: childName,
        grade: child_grades,
        is_teacher: this.state.is_teacher,
      };

      console.log("childData:", childData);
      this.setState({
        disabledChildButton: true,
      });
      if (this.state.accountFound){
        let teacher_signup_data = {
          "school": this.state.school ? this.state.school.id : "",
          "school_class": this.state.selectedSections ,
          'name': childName,
          'state': this.state.childState,
          'contact_no': this.state.phone,
          'country_code': this.state.code,
        };

        let teacher_signup_res = await this.props.createTeacherRequest(teacher_signup_data);

        await getAndSetChild(store.dispatch, {}, null, true)
        // redirect to home page
        window.location.href = "/";
        return;
      }

      if (this.state.action === "login") {
        const childRes = await this.props.createChild(
          childData,
          this.state.otpRes
        );
        if (childRes) {
          setTimeout(() => {
            this.eventLogList();
          }, 500);
          setTimeout(() => {
            if (!this.state.referrer) {
              this.props.history.push("/");
            } else {
              this.setState({ emailRequired: true });
            }
          }, 700);
        } else {
        }
      } else {

        let otpVerifyObj = {
          country_code: this.state.code,
          name: `${this.state.child}'s Parent`,
          contact_no: this.state.phone,
          otp: this.state.otp,
          user_type: "parent",
          device_type: "web",
          is_teacher: this.state.is_teacher,

          signup_token: this.state.otpRes.signup_token,
        };
        if(this.state.childState) {
          otpVerifyObj['description'] =  this.state.childState;
        }
        const res = await this.props.verifyParentOtpRequest(otpVerifyObj);
        console.log("res:", res);
        if (res && res.auth_token) {
          let teacher_signup_data = {
            school: this.state.school ? this.state.school.id : "",
            school_class: this.state.selectedSections,
            name: childName,
            state: this.state.childState,
            contact_no: this.state.phone,
            country_code: this.state.code,
          };

          let childRes = await this.props.createTeacherRequest(
            teacher_signup_data
          );


          // const childRes = await this.props.createChild(childData, res);

          // Analytics.logEvents(EVENT.ONBOARDING_ADD_CHILD)
          if (childRes) {
          await getAndSetChild(store.dispatch, {}, null, true);


            // if( this.state.schoolCodeVerifiedComplete) {
            //   let childSchool = {};
            //   if( this.state.is_teacher ) {
            //      childSchool = {
            //         "school": this.state.school ? this.state.school.id : "",
            //         "school_class": this.state.selectedSections
            //     }
            //   } else {
            //      childSchool = {
            //         "school": this.state.school ? this.state.school.id : "",
            //         "school_class": [ this.state.childSection ]
            //     }
            //   }

            //   const childSchoolRes = await this.props.addSchool(childSchool)
            // }
            window.location = "/";
          } else {
          }
        } else {
          // this.setState({ loading: false });
          this.setState({
            error: "Error, Please contact support",
          });
        }
      }
      setTimeout(() => {
        this.setState({
          disabledChildButton: false, isLoading:false,
        });
      }, 3000);
    } else {
      this.setState({ error: "Invalid Child name" });
    }

  };
  validateEmail(email) {
    const re =
      // eslint-disable-next-line no-useless-escape
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  }

  sendEmailOtp = async (e) => {
    e.preventDefault();
    // if (this.state.email && this.validateEmail(this.state.email)) {
    //   this.props.form.validateFields(async (err, values) => {
    //     if (!err) {
    //       this.setState({
    //         isSendingOtp: true,
    //         disabledButton: true,
    //         error: null,
    //       });
    //       let reqObject = {
    //         email: this.state.email,
    //       };
    //       let res = await this.props.getEmailOtpAction(reqObject);

    //       if (res.result) {
    //         this.setState({
    //           emailOtpSent: true,
    //           disabledButton: false,
    //           isSendingOtp: false,
    //         });
    //       } else if (res.data && res.data.error) {
    //         this.setState({
    //           error: res.data.error.error_detail.email[0],
    //           disabledButton: false,
    //           isSendingOtp: false,
    //         });
    //       } else {
    //         this.setState({ disabledButton: false, isSendingOtp: false });
    //       }
    //     }
    //   });
    // } else {
    //   this.setState({ error: "Please enter a valid email address" });
    // }
  };

  handleUpdateState = (key, e) => {




    console.log("key:", key);
    console.log("value:", e.target.value);
    this.setState({ [key]: e.target.value, error: null });
  };

  handleCodeChange = (key, v) => {
    if (key !== v.dialCode) {
      let number = key.replace(v.dialCode, "");
      this.setState({ code: v.dialCode, phone: number, error: null });
    } else {
      this.setState({ phone: null });
    }
  };

  onChange = async (value) => {
    // Analytics.logEvents(EVENT.ONBOARDING_SELECT_GRADE)
    this.setState({ grade: value });
    this.setState({ selectedGrade: value });
    console.log("grade changed:", value);
    if(this.state.schoolCodeVerifiedComplete){
      const list = await this.props.getClass({ school: this.state.school ? this.state.school.id : ""});
      console.log("list: ", list);
      this.setState({gradeSections: list});
    }
  };

  onSectionChange = (value) => {
    // this.setState({ selectedGrade: value });
    // console.log("hi", this.state.selectedGrade);
    this.setState({ childSection: value });
    console.log("section changed:", value);
  };

  childNameSubmit = (e) => {
    e.preventDefault();
    if (!this.state.child.match(/^[a-zA-Z0-9_ .\s]*$/)) {
        this.setState({ error: "Please enter a valid name without any special characters" });
        return;
    } else {
        this.setState({ error: null });
    }
    this.setState({ enteredName: true , loading:true});
    console.log("country code", this.state.code);
    if( this.state.code && this.state.code != 91){
      this.setState({loading: true});
      this.submitChildData()
    }
  };

  verifySchoolCode = async (e) => {
    e.preventDefault();
    console.log("clicked on verify school code");
    this.setState({ verifingSchool: true })
    const res = await this.props.getSchool(this.state.schoolCode);
    console.log("clicked on verify school code", res);
    if (res) {
      this.setState({ verifingSchool: false, school: res })
      const list = await this.props.getClass({
      school: this.state.school ? this.state.school.id : ""
    });
      const treeGrades = await this.transformData(list)
      console.log("treeGrades:", treeGrades);
      this.setState({ treeGrades: treeGrades });
    } else {
      this.setState({ verifingSchool: false, error: "Invalid School Code" })
    }

    // const treeGrades = await this.convertToTreeFormat();
  }

  continueSchool = (e) => {
    e.preventDefault();
    console.log("clicked on continue school");
    let payload = {}
    payload[EVENT.CHILD_SCHOOL] = this.state.school?.name
    payload[EVENT.SCHOOL_TAG] = this.state.school?.school_tag
    payload[EVENT.SCHOOL_CODE] = this.state.school?.school_code
    this.eventList.push({ name: EVENT.ONBOARDING_SCHOOLCODE, data: payload })
    // this.sendAnalytics(EVENT.ONBOARDING_SCHOOLCODE,payload)
    this.setState({ schoolCodeVerified: true, SchoolCodeSelected: true })

    this.setState({ schoolCodeVerifiedComplete: true });
  }

  schoolCodeVerifiedCompleteFunc = () => {
    this.setState({ schoolCodeVerifiedComplete: true })
  }

  continueWithoutSchoolCode = () => {
    console.log("continue without sc");
    this.setState({ SchoolCodeSelected: true, school: null })
  }

  verifyEmailOtp = async (e) => {
    e.preventDefault();
    if (this.state.emailOtp) {
      this.props.form.validateFields(async (err, values) => {
        if (!err) {
          this.setState({ isSendingOtp: true, disabledButton: true });
          let reqObject = {
            email: this.state.email,
            otp: this.state.emailOtp,
          };
          let res = await this.props.verifyEmailOtpAction(reqObject);
          if (res && res.is_email_verified) {
            var userData = {
              email: LocalStorage.fetch.parentData()?.email,
              sub: LocalStorage.fetch.parentData()?.id,
              name: LocalStorage.fetch.parentData()?.name,
              phone: LocalStorage.fetch.parentData()?.contact_no,
              iat: Math.round(new Date().getTime() / 1000), // token issue time
              exp: Math.round(new Date().getTime() / 1000) + 604800, // token expiration time
            };
            let url =
              "https://freadom.tribe.so/auth/sso?ssoToken=" +
              jwt.sign(userData, privateKey, { algorithm: "HS256" }) +
              "&redirect=/";
            window.open(url, "_self");
            // window.location.assign(tribeUrl);
          } else if (res.error && res.error.error_code === 1002) {
            this.setState({
              error: "Invalid OTP. Please try again.",
              disabledButton: false,
              isSendingOtp: false,
            });
          }
        }
      });
      this.setState({ SchoolCodeSelected: true, schoolCodeYesNoPage: true });
    } else {
      this.setState({ error: "Invalid OTP. Please try again." });
    }
  };

  handleclick = (e) => {
    // setTimeout(() => this.setState({ isbuttonDisabled: false }), 5000);
    this.setState({ isbuttonDisabled: true });
    this.verifyOtp(e);
  };

  treeselectOnChange = (newValue, label, extra) => {


    console.log("newValue:", newValue, "label:", label, "extra:", extra);
    // check newValue is a string or array,if string the log string
    if (typeof newValue === "string") {
      newValue = [newValue];
    }

    let selectedGradesList = this.getSelectedGrades(newValue);
    console.log("selectedGrades list:", selectedGradesList);
    if (selectedGradesList.length > 0)
      this.setState({ selectedGrades: selectedGradesList, selectedSections: newValue });

    console.log("grades:", this.state.selectedGrades);
    console.log("sections:", this.state.selectedSections);
  }

  getSelectedGrades = (selectedSection) => {
    console.log("getselectedgrades:", selectedSection)
    let selectedGradeIDs = new Set();
    // for(const section in selectedSection){
    //     console.log("section:",section);
    //     let parentGrade = this.findParentValue(section);
    //     if(parentGrade) {
    //         selectedGradeIDs.push(parentGrade)
    //     }
    // }

    for (let idx = 0; idx < selectedSection.length; idx += 1) {
      let parentGrade = this.findParentValue(selectedSection[idx]);
      console.log("parentgrade:", parentGrade);
      if (parentGrade) {
        selectedGradeIDs.add(parentGrade);
      }
    }
    return Array.from(selectedGradeIDs);
  }

  findParentValue = (sectionID, idx, array) => {
    console.log("findparentvalue:", sectionID);
    let data = this.state.treeGrades;
    console.log("data:", data);
    for (const item of data) {
      console.log("item:", item);
      console.log(item, sectionID);
      if (item.value === sectionID) {
        return item.value; // Found the value in the current level
      }
      if (item.children && item.children.length > 0) {

        for (const subItem of item.children) {
          console.log("sub item:", subItem);
          if (subItem.value == sectionID) {
            console.log("item found:", item.value);
            return item.value
          }
        }
      }
    }

    return null; // Value not found in the hierarchy
  }

//  gradeMappings = [
//   { "id": "1bf9526b-6c32-4198-9174-e5f55edec9b5", "name": "Nursery", "age_group": "3-4" },
//   { "id": "bb64c16e-16cc-4add-ba70-ffef32c10f2f", "name": "Junior KG", "age_group": "4-5" },
//   { "id": "3e190874-fd42-43f8-b78e-1e5b657c434d", "name": "Senior KG", "age_group": "5-6" },
//   { "id": "b1794ec1-4623-49c8-9763-ee31f84ab98b", "name": "Grade 1", "age_group": "6-7" },
//   { "id": "9ff47f25-71b4-495d-afc7-0c48babf38e2", "name": "Grade 2", "age_group": "7-8" },
//   { "id": "c8be905b-77a6-4404-aaa9-e55fc6456e22", "name": "Grade 3", "age_group": "8-9" },
//   { "id": "27e6c71c-0dc9-4832-b221-0bd047588e64", "name": "Grade 4", "age_group": "9-10" },
//   { "id": "df8d3b07-95b2-4903-a35b-5c77ab10bf72", "name": "Grade 5", "age_group": "10-11" },
//   { "id": "cfe7b2db-5ddb-4453-8fc9-19608cf44cd4", "name": "Grade 6", "age_group": "11-12" },
//   { "id": "f8327420-6ca9-453b-819e-f0a506c34cc5", "name": "Grade 7", "age_group": "12-13" },
//   { "id": "95240892-6fbb-4447-9f0d-25ae082e3a44", "name": "Grade 8", "age_group": "13-14" }
// ];

// Convert the array to a mapping of grade IDs to names for easier lookup

 gradeNameMapping = ()=>{
   let grades = this.props.gradeList.reduce((acc, grade) => {
        acc[grade.id] = grade.name;
        return acc;
      }, {});
      console.log(grades,this.props.gradeList)
    return grades
 }

transformData(data) {
  const gradeMap = {};

  data.forEach((item) => {
    let gradeNameMap = this.gradeNameMapping()
    const gradeName = gradeNameMap[item.grade] || "Unknown Grade";
    const gradeId = item.grade;

    // Create grade if it doesn't exist in the map
    if (!gradeMap[gradeId]) {
      gradeMap[gradeId] = {
        value: gradeId,
        title: gradeName,
        children: []
      };
    }

    // Add the class to the grade's children
    gradeMap[gradeId].children.push({
      value: item.id,
      title: `${gradeName} - ${item.name}`
    });
  });

  // Convert map to array
  return Object.values(gradeMap);
}

  convertToTreeFormat = async () => {
    const gradeList = this.props.gradeList;
    console.log("gradeList:", gradeList);

    const recursiveConvert = async (list) => {
      return await Promise.all(list.map(async (item) => ({
        value: item.id,
        title: item.name,
        children: item.children ? await recursiveConvert(item.children) : await getClass(item.id, item.name),
      })));
    };

    const getClass = async (grade_id,grade_name) => {
      const list = await this.props.getClass({ school: this.state.school ? this.state.school.id : "" });
      console.log("list: ", list);
      let treeClassFormat = [];

      for (let idx = 0; idx < list?.length; idx += 1) {
        let subclass = {
          value: list[idx].id,
          title: grade_name + " - " +list[idx].name,
          children: null
        };
        treeClassFormat.push(subclass);
      }

      console.log("treeClassFormat:", treeClassFormat);
      // Return the processed data or do something with 'list' as needed
      return treeClassFormat;
    };

    const removeNoClassGrades = (objects) => {
      return objects.filter(obj => obj.children !== null && obj.children !== undefined && obj.children.length !== 0);
    }

    let data = await recursiveConvert(gradeList);
    return removeNoClassGrades(data);

  };

  doYouHaveSchoolCode = (event) => {
    console.log("doYouHaveSchoolCode");
    console.log("event:", event);
    console.log("school:", this.state.school);
    // Determine which button was clicked
    const choice = event.nativeEvent.submitter.innerText.toLowerCase();

    if (choice === 'yes') {
      console.log("yes");
      this.setState({ SchoolCodeSelected: true, schoolCodeYesNoPage: true });
    } else if (choice === 'no') {
      this.setState({ SchoolCodeSelected: false, schoolCodeYesNoPage: true });
    }

  }
  gradeSelectionComplete = () => {
    this.setState({ gradeSelected: true });
    console.log("grade is over:",this.state.grade);
  }

  render() {
    const { getFieldDecorator, getFieldsError } = this.props.form;
    const {
      email,
      verfied,
      otpSent,
      child,
      enterChildName,
      emailOtpSent,
      emailVerfied,
      emailRequired,
      SchoolCodeSelected,
      School,
      school,
      verifingSchool,
      enteredName,
      childState,
      is_teacher,
      schoolCodeYesNoPage,
      gradeSelected,
      schoolCodeVerified,
      schoolCodeVerifiedComplete,
      statesData,
      gradeSections,
      loading,
      code,
      accountExists,
      error
    } = this.state;

    return (
      <div>
        <Header />
        <div className="signup-container row">
          <div className="left-part column">
            <img
              src={LoginImg}
              alt="user illustration"
              className="user-illustration"
            />
            {/* <img src={FreadomLogo} className = {'image-banner'} alt="logo"/> */}
            {/* <div className="title">Designed to help you raise</div>
                    <div className="gradient-text">A happy reader</div>
                    <p className="helper-text">
                        Track the progress insights of your children and manage their
                        interests to get a more personalised experience
                    </p> */}
          </div>
          <div
            className={
              "right-part column" +
              (this.state.inputFocused ? " input-focused" : "")
            }
          >
            {/* <img src={FreadomLogo} className="mobile-logo" alt="logo"/> */}
            {
              <div className="login-card">
                {/* <div className="heading">Good {getTime()}!</div> */}
                <div className="heading">
                  Dive into a world of Hindi and English language learning with
                  HELLO!
                </div>

                <div className="sub-heading">
                  Building a world of confident bilingual learners!
                </div>
                <div>
                  {!otpSent ? (
                    <Form layout="vertical" onSubmit={this.sendOtp}>
                      <div className="otp-label">Enter your phone number</div>

                      <div>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <Form.Item
                            style={{
                              flex: 1,
                              marginLeft: 0,
                              marginBottom: "10px",
                            }}
                          >
                            {getFieldDecorator("phone", {
                              rules: [
                                {
                                  required: true,
                                  message: "Please enter phone number",
                                  validateTrigger: "onBlur",
                                },
                              ],
                              validateTrigger: "onBlur",
                            })(
                              <PhoneInput
                                placeholder="Your phone number"
                                value={this.state.phone}
                                autoFormat={true}
                                country={"in"}
                                inputClass="input-class width-100"
                                countryCodeEditable={false}
                                buttonClass="flag-class"
                                onKeyDown={(event) =>
                                  !otpSent &&
                                  event.key === "Enter" &&
                                  this.sendOtp(event)
                                }
                                inputProps={{
                                  name: "phone",
                                  required: true,
                                  autoFocus: true,
                                  maxLength: 19, // Limit the number of characters in the input field
                                }}
                                onChange={(e, v) => this.handleCodeChange(e, v)}
                                enableLongNumbers={true}
                              />
                            )}
                          </Form.Item>
                        </div>
                        <span style={{ color: "red", fontSize: 14 }}>
                          {this.state.error}
                        </span>
                      </div>
                      <Button
                        type="primary"
                        disabled={hasErrors(getFieldsError())}
                        htmlType="submit"
                        // disabled={this.state.disabledOtpButton}
                        loading={this.state.isSendingOtp}
                      >
                        {/* {otpSent ? "Re-Send OTP" : "Send OTP"} */}
                        Continue
                      </Button>

                      {/* <span className={'signup-text'}>Already a user? </span>
                                <Link to="/login" className="signup-link"> Login now</Link> */}
                    </Form>
                  ) : null}
                  {this.state.emailRequired ? (
                    <div>
                      {!emailOtpSent ? (
                        <Form layout="vertical" onSubmit={this.sendEmailOtp}>
                          <div className="otp-label">Enter your email</div>
                          <Form.Item style={{ margin: 0 }}>
                            <Input
                              className={"input-class"}
                              size="default"
                              value={email}
                              placeholder="Your email ID"
                              onChange={(e) =>
                                this.handleUpdateState("email", e)
                              }
                            />
                            <span style={{ color: "red", fontSize: 14 }}>
                              {this.state.error}
                            </span>
                          </Form.Item>

                          <Button
                            type="primary"
                            disabled={hasErrors(getFieldsError())}
                            htmlType="submit"
                            // disabled={this.state.disabledButton}
                            loading={this.state.isSendingOtp}
                          >
                            Continue
                          </Button>
                        </Form>
                      ) : null}

                      {!emailVerfied && emailOtpSent && !error ? (
                        <form onSubmit={this.verifyEmailOtp}>
                          <div className="otp-label">
                            Enter the 4-digit code you received
                          </div>
                          <div style={{ marginBottom: 24 }}>
                            <OtpInput
                              onChange={(otp) =>
                                this.setState({ emailOtp: otp, error: null })
                              }
                              value={this.state.emailOtp}
                              numInputs={4}
                              isInputNum={true}
                              containerStyle={{
                                display: "flex",
                                justifyContent: "space-between",
                                width: "300px",
                                marginBottom: "10px",
                              }}
                              separator={<span></span>}
                              inputStyle={otpBoxStyle}
                              isDisabled={!emailOtpSent}
                            />
                            <div style={{ color: "red", fontSize: 14 }}>
                              {this.state.error}
                            </div>
                          </div>
                          <Button
                            type="primary"
                            disabled={!this.state.emailOtpSent}
                            htmlType="submit"
                          >
                            Confirm
                          </Button>
                          {/* <div style={{display:"flex", alignItems:"center"}}>
                                                    <div className={'signup-text'}>Didn’t receive the OTP? &nbsp;</div>
                                                    {this.state.showResend ?<div>  <Button className="resend-link"  onClick={this.sendOtp}> Resend now</Button>
                                                    <span className="seprator" ></span>
                                                    <Button className="resend-link" onClick={this.callOtp}>Receive call</Button></div>
                                                    :  <Button className={'timer-text'}> {" "}0: {this.state.timer} sec</Button>}
                                                </div> */}
                        </form>
                      ) : null}
                    </div>
                  ) : null}

                  {/* {accountExists && !emailRequired && verfied && !schoolCodeYesNoPage ? (
                    <form onSubmit={this.doYouHaveSchoolCode}>
                      <div className="otp-label">Do you have school code ?</div>
                      <div className="d-flex">
                        <Button
                          type="primary"
                          htmlType="submit"
                          className="school-code-yes-no"
                        >
                          Yes
                        </Button>
                        <p style={{ padding: '10px' }}></p>
                        <Button
                          type="primary"
                          htmlType="submit"
                          className="school-code-yes-no"
                        >
                          No
                        </Button>
                      </div>
                    </form>
                  ) : null} */}

                  {accountExists &&
                  !emailRequired &&
                  verfied &&
                  schoolCodeYesNoPage &&
                  SchoolCodeSelected &&
                  !schoolCodeVerifiedComplete ? (
                    <Form layout="vertical">
                      <div className="otp-label">Please enter the valid school code</div>
                      <form
                        onSubmit={
                          !school
                            ? this.verifySchoolCode
                            : !schoolCodeVerified
                            ? this.continueSchool
                            : this.schoolCodeVerifiedCompleteFunc
                        }
                      >
                        <OtpInput
                          onChange={(schoolCode) => {
                            this.setState({
                              schoolCode: schoolCode,
                              error: null,
                            });
                          }}
                          value={this.state.schoolCode}
                          numInputs={6}
                          containerStyle={{
                            display: "flex",
                            justifyContent: "space-between",
                            width: "300px",
                            marginBottom: "20px",
                          }}
                          shouldAutoFocus
                          separator={<span></span>}
                          inputStyle={otpBoxStyle}
                          isDisabled={verifingSchool}
                        />
                        <div style={{ color: "red", fontSize: 14 }}>
                          {this.state.error}
                        </div>
                        {school ? (
                          <Input
                            style={{
                              marginTop: "10px",
                              marginBottom: "30px",
                              // color: "white",
                              // backgroundImage:
                              //   "linear-gradient(360deg, #1B0838, #1B0838, #1B0838)",
                              // border: "purple 2px solid",
                            }}
                            size="large"
                            value={school.name}
                            disabled={true}
                          />
                        ) : null}
                        <Button
                          type="primary"
                          disabled={verifingSchool}
                          htmlType="submit"
                        >
                          Continue
                        </Button>
                      </form>
                    </Form>
                  ) : null}
                  {accountExists &&
                  !emailRequired &&
                  verfied &&
                  schoolCodeYesNoPage &&
                  SchoolCodeSelected &&
                  schoolCodeVerifiedComplete &&
                  !gradeSelected ? (
                    <Form
                      layout="vertical"
                      onSubmit={this.gradeSelectionComplete}
                    >
                      <div className="otp-label">
                        Pick the grades you teach in
                      </div>

                      <div>
                        {/* FREAD-1237 */}
                        <div
                          style={{
                            // display: 'flex'//, alignItems: 'center'
                            width: "100%",
                          }}
                        >
                          <Form.Item style={{ marginBottom: 0, with: "100%" }}>
                            {getFieldDecorator("grade", {
                              rules: [
                                {
                                  required: true,
                                  message: "Please select atleast one grade",
                                },
                              ],
                            })(
                              <TreeSelect
                                showSearch
                                dropdownStyle={{
                                  maxHeight: 230,
                                  width: 420,
                                  overflow: "auto",
                                  zIndex: "100000",
                                  left: 660,
                                }}
                                placeholder={
                                  <span style={{ color: "#808080" }}>
                                    Select all the grades you teach in
                                  </span>
                                }
                                allowClear
                                treeDefaultExpandAll={true}
                                multiple={true}
                                treeCheckable={true}
                                onChange={this.treeselectOnChange}
                                treeData={this.state.treeGrades}
                              />
                            )}
                          </Form.Item>
                          &nbsp;
                        </div>
                        <span style={{ color: "red", fontSize: 14 }}>
                          {this.state.error}
                        </span>
                      </div>
                      <Button
                        type="primary"
                        disabled={hasErrors(getFieldsError())}
                        htmlType="submit"
                        // disabled={this.state.disabledOtpButton}
                        loading={this.state.isSendingOtp}
                        // onClick={() => {this.setState({ isLoading: true })}}
                      >
                        Continue
                      </Button>
                    </Form>
                  ) : null}
                  {accountExists &&
                  !emailRequired &&
                  verfied &&
                  schoolCodeYesNoPage &&
                  (!SchoolCodeSelected || schoolCodeVerifiedComplete) &&
                  !gradeSelected &&
                  !this.state.schoolCodeVerifiedComplete ? (
                    <Form
                      layout="vertical"
                      onSubmit={this.gradeSelectionComplete}
                    >
                      <div className="otp-label">
                        Pick the grade your child is in
                      </div>
                      <div>
                        <div>
                          <Form.Item
                            style={{ marginBottom: 0, color: "black" }}
                          >
                            {getFieldDecorator("grade", {
                              rules: [
                                {
                                  required: true,
                                  message: "select all the grades you teach in",
                                },
                              ],
                            })(
                              <Select
                                showSearch
                                mode={"multiple"}
                                style={{ width: "500px", height: 40 }}
                                placeholder={
                                  <span style={{ color: "#808080" }}>
                                    Select grade
                                  </span>
                                }
                                optionFilterProp="children"
                                dropdownClassName="drop-down"
                                dropdownStyle={{ width: "400" }} // Set the width of the dropdown
                                onChange={this.onChange}
                                onFocus={this.onFocus}
                                onBlur={this.onBlur}
                                popupMatchSelectWidth={true}
                                onSearch={this.onSearch}
                                filterOption={(input, option) =>
                                  option.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                                }
                              >
                                {this.props.gradeList &&
                                  this.props.gradeList.length > 0 &&
                                  this.props.gradeList.map((m, index) => (
                                    <Option key={m.id} value={m.id}>
                                      {m.name}
                                    </Option>
                                  ))}
                              </Select>
                            )}
                          </Form.Item>
                          <br />
                          {schoolCodeVerifiedComplete &&
                          this.state.gradeSections.length > 0 ? (
                            <Form.Item style={{ marginBottom: 0 }}>
                              {getFieldDecorator("section", {
                                rules: [
                                  {
                                    required: true,
                                    message: "Please select section",
                                  },
                                ],
                              })(
                                <Select
                                  showSearch
                                  mode={"multiple"}
                                  style={{ width: "100%", height: 40 }}
                                  placeholder={
                                    <span style={{ color: "#808080" }}>
                                      Select Section
                                    </span>
                                  }
                                  optionFilterProp="Section"
                                  dropdownClassName="drop-down"
                                  onChange={this.onSectionChange}
                                  onFocus={this.onFocus}
                                  onBlur={this.onBlur}
                                  onSearch={this.onSearch}
                                  // filterOption={(input, option) =>
                                  // option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                  // }
                                >
                                  {this.state.gradeSections &&
                                    this.state.gradeSections.length > 0 &&
                                    this.state.gradeSections.map((m, index) => (
                                      <Option key={m.id} value={m.id}>
                                        {m.name}
                                      </Option>
                                    ))}
                                </Select>
                              )}
                            </Form.Item>
                          ) : null}
                        </div>
                      </div>
                      <br></br>
                      <Button
                        type="primary"
                        disabled={
                          hasErrors(getFieldsError()) ||
                          !this.state.selectedGrade
                        }
                        htmlType="submit"
                        // disabled={this.state.disabledOtpButton}
                        loading={this.state.isSendingOtp}
                      >
                        Continue
                      </Button>
                    </Form>
                  ) : null}
                  {accountExists &&
                  !emailRequired &&
                  verfied &&
                  schoolCodeYesNoPage &&
                  ((SchoolCodeSelected && schoolCodeVerifiedComplete) ||
                    !SchoolCodeSelected) &&
                  gradeSelected &&
                  !enteredName ? (
                    <Form layout="vertical" onSubmit={this.childNameSubmit}>
                      {<div className="otp-label">Enter your name</div>}
                      <Form.Item
                        style={{ margin: 0, marginBottom: "10px !important" }}
                      >
                        <Input
                          className={"input-class"}
                          size="large"
                          value={child}
                          placeholder={"Your name"}
                          onChange={(e) => this.handleUpdateState("child", e)}
                          style={{ marginBottom: "10px !important" }}
                        />
                        <span style={{ color: "red", fontSize: 14 }}>
                          {this.state.error}
                        </span>
                      </Form.Item>
                      {/* <div style={{ color: "red", fontSize: 14 }}>
                        {this.state.error}
                      </div> */}
                      <Button
                        type="primary"
                        disabled={
                          hasErrors(getFieldsError()) || !this.state.child
                        }
                        htmlType="submit"
                        // disabled={this.state.disabledChildButton}
                        loading={this.state.isSendingOtp}
                      >
                        Continue
                      </Button>
                    </Form>
                  ) : null}

                  {accountExists &&
                  !emailRequired &&
                  verfied &&
                  schoolCodeYesNoPage &&
                  ((SchoolCodeSelected && school) || !SchoolCodeSelected) &&
                  gradeSelected &&
                  enteredName &&
                  this.state.code == 91 ? (
                    // <Form layout="vertical" onSubmit={this.submitChildData}>
                    //   <div className="otp-label">Enter your State</div>
                    //   <Form.Item style={{ margin: 0 }}>
                    //     <Input
                    //       className={"input-class"}
                    //       size="large"
                    //       value={childState}
                    //       placeholder="Your State"
                    //       onChange={(e) => this.handleUpdateState("childState", e)}
                    //     />
                    //     <span style={{ color: "red", fontSize: 14 }}>
                    //       {this.state.error}
                    //     </span>
                    //   </Form.Item>

                    //   <Button
                    //     type="primary"
                    //     disabled={hasErrors(getFieldsError())}
                    //     htmlType="submit"
                    //     // disabled={this.state.disabledChildButton}
                    //     loading={this.state.isSendingOtp}
                    //   >
                    //     Continue
                    //   </Button>
                    // </Form>
                    <Form layout="vertical" onSubmit={this.submitChildData}>
                      <div className="otp-label">Select your state</div>
                      <Form.Item style={{ margin: 0 }}>
                        <Select
                          showSearch
                          // optionFilterProp="children"
                          // filterOption={(input, option) =>
                          //   option.props.children
                          //     .toLowerCase()
                          //     .indexOf(input.toLowerCase()) >= 0
                          // }
                          defaultValue="Please select state"
                          style={{ width: "100%" }}
                          onChange={(data) => {
                            this.setState({ childState: data });
                          }}
                        >
                          {statesData.map((state) => (
                            <Option key={state} value={state}>
                              {state}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                      <div style={{ color: "red", fontSize: 14 }}>
                        {this.state.error}
                      </div>
                      <Button
                        type="primary"
                        // disabled={hasErrors(getFieldsError())}
                        htmlType="submit"
                        disabled={
                          !this.state.childState ||
                          this.state.childState === "Please select state"
                        }
                        loading={this.state.isLoading}
                      >
                        Continue
                      </Button>
                    </Form>
                  ) : null}

                  {(loading && code != 91) || (error && enterChildName) ? (
                    <div>
                      {!accountExists ? (
                        <img
                          style={{ height: "5rem" }}
                          className="loader"
                          src={loader}
                          alt="loading"
                        />
                      ) : null}
                      <div style={{ color: "red", fontSize: 14 }}>
                        {this.state.error}
                      </div>
                    </div>
                  ) : null}

                  {!emailRequired && verfied === false && otpSent && !error ? (
                    <form onSubmit={this.verifyOtp}>
                      <div className="otp-label">
                        Enter the 4-digit OTP you received
                      </div>
                      <div style={{ marginBottom: 24 }}>
                        <OtpInput
                          onChange={(otp) =>
                            this.setState({ otp: otp, error: null })
                          }
                          value={this.state.otp}
                          numInputs={4}
                          isInputNum={true}
                          containerStyle={{
                            display: "flex",
                            justifyContent: "space-between",
                            width: "300px",
                            marginBottom: "10px",
                          }}
                          separator={<span></span>}
                          inputStyle={otpBoxStyle}
                          isDisabled={!otpSent}
                        />
                        <div style={{ color: "red", fontSize: 14 }}>
                          {this.state.error}
                        </div>
                      </div>
                      <Button
                        type="primary"
                        disabled={this.state.isbuttonDisabled}
                        htmlType="submit"
                        onClick={(e) => this.handleclick(e)}
                      >
                        Confirm
                      </Button>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <div className={"signup-text"}>
                          Didn’t receive the OTP? &nbsp;
                        </div>
                        {/* FREAD-1234 */}
                        {this.state.showResend ? (
                          <div className="btn_sep_1234">
                            {" "}
                            <Button
                              className="resend-link"
                              onClick={this.sendOtp}
                            >
                              {" "}
                              Resend now
                            </Button>
                            <span className="seprator"></span>
                            <Button
                              className="resend-link"
                              onClick={this.callOtp}
                            >
                              Receive call
                            </Button>
                          </div>
                        ) : (
                          <Button className={"timer-text"}>
                            {" "}
                            0: {this.state.timer} sec
                          </Button>
                        )}
                      </div>
                    </form>
                  ) : null}
                </div>
              </div>
            }
          </div>
        </div>
      </div>
    );
  }
}

const connectedComponent = connect(
  ({ user }) => ({ ...user }),
  (dispatch) => bindActionCreators({ ...userActions }, dispatch)
)(TeacherSignup);

export default Form.create({ name: "teacher_Signup_Form" })(connectedComponent);
