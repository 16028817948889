import React, { useEffect } from 'react'
import { useState } from "react";
import { pdfjs, Page, Document } from "react-pdf";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as storyActions from "store/story/actions";
import { Button } from "antd";
import * as EVENT from "../../utils/eventKeys";
import { v4 as uuidv4 } from "uuid";
import Quiz from "components/Quiz";
import ZoomInIcon from 'assets/icons/zoom-in.svg'
import ZoomoutIcon from 'assets/icons/zoom-out.svg'
import RightArrowIcon from 'assets/icons/right_arrow.svg'
import LeftArrowIcon from 'assets/icons/left_arrow.svg'

const PdfView = (props) => {

    pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

    const [totalPages, setTotalPages] = useState(0);
    const [pageNumber, setPageNumber] = useState(1);
    const [pageScale, setPageScale] = useState(0.675);
    const [storyData, setStoryData] = useState({});
    const [state, setState] = useState({
        quizOpened: false,
        continueQuiz: false,
        mediaOpen: false,
        isLiked: false,
        viewId: uuidv4(),
        total_time: 0,
    });

    useEffect(() => {
        setStoryData(props.dataStory);
    }, []);

    function onDocumentLoadSuccess({ numPages }) {
        setTotalPages(numPages);
    }
    function onDocumentLoadError(error) {
        console.error('Failed to load PDF:', error);
    }

    function handleZoomIn() {
        if (pageScale < 3.0) {

            setPageScale(pageScale + 0.2);
        }

    }

    function handleZoomOut() {
        if (pageScale > 0.3) {
            setPageScale(pageScale - 0.2);
        }
    }

    function handleNext() {
        if (pageNumber < totalPages) {
            setPageNumber(pageNumber + 1);
            setPageScale(0.675)
        }
    }
    function handlePrevious() {
        if (pageNumber > 0) {
            setPageScale(0.675)
            setPageNumber(pageNumber - 1);
        }
    }

    const finishQuiz = async () => {

        props?.sendStoryAnalytics(EVENT.MARK_COMPLETE_STORY, storyData);
        const { dataStory } = props;
        if (dataStory.is_quiz_taken || dataStory?.status === 'completed') {
            props.closeModal();
            return;
        }
        let res = await props.finishStoryRequest(storyData.id);
        if (res) {
            await props.closeModal();
            if (dataStory.has_mcq) {
                // setState({ quizOpened: true });
                // Mixpanel.track("QA-After Finish Story", this.props.dataStory);
                props.openQuiz()
            } else if (dataStory?.status !== 'completed') {
                props.updateDetailedStory(res.status);
                setStoryData((prevState) => ({
                    storyData: {
                        ...prevState.storyData,
                        status: res.status,
                    },
                }));
                let streakPayload = {
                    action_id: storyData.id,
                    action_type: "story",
                };
                // call the createStreakRequest to update the streak
                const { result } = await props.createStreakRequest(streakPayload);

                if (!result.IsUpdated) {
                    // append streak to the dataStory variable
                    res.streak = 1;
                }
                console.log("no MCQ story");
                props.openSuccessModal(
                    res.result ? res.result : res.data ? res.data : res
                );
            } else {
                props.closeModal();
                return;
            }
        }
    };

    const lastQuestionAnswered = async (dataStory) => {
        setState({ quizOpened: false });
        console.log("datastory:", dataStory);
        let streakPayload = {
            action_id: storyData.id,
            action_type: "story",
        };
        // call the await createStreakRequest to update the streak
        const { result } = await props.createStreakRequest(streakPayload);
        if (!result.IsUpdated) {
            // append streak to the dataStory variable
            dataStory.streak = 1;
        }
        props.openSuccessModal(dataStory);
    };

    return (
        <div className="pdf-container">
            {
                window.innerWidth > 650 ?
                    <>
                        <div className='pdf_page'>
                            <button className='pdf_control_btn' onClick={handlePrevious} disabled={pageNumber === 1}>
                                <img src={LeftArrowIcon} alt={"<"} />
                            </button>

                            <div className="pdf-page-content">
                                <Document file={props.url} loading={<div className='pdf_loading_text'>Loading Book...</div>} onLoadSuccess={onDocumentLoadSuccess} onLoadError={onDocumentLoadError}>
                                    <Page renderAnnotationLayer={false}
                                        pageNumber={pageNumber} scale={pageScale} />
                                </Document>
                            </div>
                            <button className='pdf_control_btn' onClick={handleNext} disabled={pageNumber === totalPages}>
                                <img src={RightArrowIcon} alt={">"} />
                            </button>
                        </div>
                        <div className="pdf-toolbar">
                            <div className="button-container">
                                <button className='pdf_control_btn' onClick={handleZoomIn} disabled={pageScale >= 3}>
                                    <img src={ZoomInIcon} alt="+" />
                                </button>
                            </div>
                            <div className="pdf-page-count">
                                Page {pageNumber} of {totalPages}
                            </div>
                            <div className="button-container">
                                <button className='pdf_control_btn' onClick={handleZoomOut} disabled={pageScale <= 0.3}>
                                    <img src={ZoomoutIcon} alt="-" />
                                </button>
                            </div>
                        </div>
                        {!props.activityPage && pageNumber === totalPages && (
                            <Button
                                type="primary"
                                className="primary-btn ml-16 finish_btn"
                                size="large"
                                //style={{ marginLeft: "16px" }}
                                onClick={finishQuiz}
                            >
                                Finish
                            </Button>
                        )}
                    </>
                    :
                    <>
                        <div className="pdf-page-content">
                            <Document file={props.url} loading={<div className='pdf_loading_text'>Loading Book...</div>} onLoadSuccess={onDocumentLoadSuccess} onLoadError={onDocumentLoadError}>
                                <Page renderAnnotationLayer={false} pageNumber={pageNumber} scale={pageScale} />
                            </Document>
                        </div>
                        <div className="pdf-toolbar">
                            <div className="button-container">
                                <button className='pdf_control_btn' onClick={handlePrevious} disabled={pageNumber === 1}>
                                    <img src={LeftArrowIcon} alt={"<"} />
                                </button>
                                <button className='pdf_control_btn' onClick={handleZoomIn} disabled={pageScale >= 3}>
                                    <img src={ZoomInIcon} alt="+" />
                                </button>
                            </div>
                            <div className="pdf-page-count">
                                Page {pageNumber} of {totalPages}
                            </div>
                            <div className="button-container">
                                <button className='pdf_control_btn' onClick={handleZoomOut} disabled={pageScale <= 0.3}>
                                    <img src={ZoomoutIcon} alt="-" />
                                </button>
                                {pageNumber === totalPages && !props.activityPage ? (
                                    <Button
                                        type="primary"
                                        className="primary-btn finish_btn_small"
                                        size="large"
                                        style={{ margin: "0px !important" }}
                                        onClick={finishQuiz}
                                    >
                                        Finish
                                    </Button>
                                ) : (
                                    <button
                                        className="pdf_control_btn"
                                        onClick={handleNext}
                                        disabled={pageNumber === totalPages}
                                    >
                                        <img src={RightArrowIcon} alt={">"} />
                                    </button>
                                )}
                            </div>
                        </div>
                    </>
            }
            {state.quizOpened && (
                <div className="quiz_container">
                    <Quiz
                        viewId={state.viewId}
                        source={props.source}
                        questions={props.questions}
                        finalAnswer={lastQuestionAnswered}
                        closeQuiz={() => setState({ quizOpened: false })}
                        storyData={storyData}
                        isStory={true}
                    />
                </div>
            )}
        </div>
    );
}

export default connect(
    ({ detailedStory }) => ({ ...detailedStory }),
    (dispatch) => bindActionCreators({ ...storyActions }, dispatch)
)(PdfView);
