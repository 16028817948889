import React, { Component } from "react";
import { Avatar, Dropdown, Icon, Modal } from "antd";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { storage as LocalStorage } from "services/config/storage";
import { bindActionCreators } from "redux";
import * as userActions from "store/user/actions";
import * as teacherActions from "store/teacher/actions";
import * as storyActions from "store/story/actions";
import Freadom from "assets/image/Hello_Logo.png";
import MadhubanLogo from "assets/image/madhuban_logo.svg"
import CommunityIcon from "assets/image/nav-community.png";
import FeedIcon from "assets/image/feed_icon.svg";
import TrophyIcon from "assets/image/trophy.svg";
import guided_path from "assets/image/guided_path.svg";
import StoryIcon from "assets/image/stories_icon.svg";
import NewsIcon from "assets/image/news_icon.svg";
import SearchIcon from "assets/image/search.svg";
import ActivityIcon from "assets/image/activities_icon.svg";
import QuizIcon from "assets/image/dice.png";
import "./Navbar.scss";
import BookSummary from "components/bookSummary";
import * as activityActions from "store/activities/actions";
import * as homeActions from "store/home/actions";
import ActivityModal from "components/activityModal";
import SettingsIcon from "assets/image/settings-menu.png";
import LogoutIcon from "assets/image/logout-menu.png";
import store from "../../store";
import * as EVENT from "../../utils/eventKeys";
import { Analytics } from "../../services/analytics";
import ParentProfile from "../ParentProfile";
import ContentSrch from "./ContentSrch";
import TeacherSearch from "./Teacher_Search";
import { getSourcePage } from "../../utils/commonUtils";
import { communityAnalytics } from "../../utils/AnalyticsHelper";
import AddProfile from "../AddProfile";
import { getAndSetChild } from "../../store/user/actions";
import SearchModal from "./SearchModal";

let level = [
  { name: "Stories", value: "story", selected: true },
  {
    name: "Activities",
    value: "activity",
    selected: false,
  },
];
var jwt = require("jsonwebtoken");
var privateKey = "056c1e10b04954ccdae40b564ffcff4882892697044a6ede";

class Navbar extends Component {
  constructor(props) {
    super(props);
    this.handleButtonPress = this.handleButtonPress.bind(this);
    this.handleButtonRelease = this.handleButtonRelease.bind(this);
    this.handleonMouseLeaveRelease = this.handleonMouseLeaveRelease.bind(this);
  }

  state = {
    switchingChild: false,
    navbarOpened: false,
    defaultChildData: {},
    resultList: [],
    overallSearchResult: [],
    showSearch: false,
    selectedResult: null,
    isModalOpen: false,
    filterData: level,
    selectedFilter: level[0],
    searchString: null,
    shwContentSrch: false,
    selectedLevel: null,
    activeActivityPackData: null,
    isActivityModalOpen: false,
    activeActivityData: {},
    activeActivityQuestionsList: [],
    successModalOpened: false,
    successModalData: {},
    isActivityPack: false,
    activeReadingChallengeData: {},
    activeReadingChallengeQuestionsList: [],
    isReadingChallenge: false,
    section: null,
    openParentProfile: false,
    emailCheckFor: "profileSetting",
    parentData: LocalStorage.fetch.parentData(),
    openAddProfile: false,
    updateUserFlag: false,
    showSearchModal:false,
    dataClick:false,
    navbarLinks: [
      {
        title: "Feed",
        url: ["/"],
        icon: FeedIcon,
      },
      {
        title: "Guidedpath",
        url: ["/guided-path"],
        icon: guided_path,
      },
      {
        title: "Stories",
        url: ["/story"],
        icon: StoryIcon,
      },
      {
        title: "News",
        url: ["/news"],
        icon: NewsIcon,
      },
      {
        title: "Activities",
        url: ["/activities"],
        icon: ActivityIcon,
      },
      //FREAD-1270
      {
        title: "Leaderboard",
        url: ["/Leaderboard"],
        icon: TrophyIcon,
      },
      {
        title: "Play",
        url: ["/quiz"],
        icon: QuizIcon,
      },
    ],

    navbarLinks2: [
    ],
    navbarLinks3: [],
  };
  handleSwitchChild = async (id) => {
    userActions.batchIdUpdate(id);
    this.setState({ switchingChild: true });
    const sessionObj = {
      started_at: this.props.startSessionTime,
      ended_at: new Date(),
    };
    await this.props.callSessionSet(sessionObj);
    this.logAnalytics("Logout");

    let res = await this.props.switchChild({ child_id: id });
    if (res?.status === 404) {
      this.setState({ switchingChild: false });

      if (window.location.pathname === "/user") {
        // return this.props.history.push("/flive");
        return this.props.history.replace("/flive");
      }
      if (
        window.location.pathname == "/guided-path/" &&
        window.location.search !=''
      ) {
        return window.location.replace("/guided-path");
      }

      return window.location.reload();
      //FREAD-1447
    }

    this.setState({ switchingChild: false });

    //if on /flive or /profile-complete, then on child switch route to student/profile
    let allowedRoutes = ["/profile-complete", "/user", "/student/profile/edit"];
    if (window.location.pathname === "/flive") {
      this.props.history.push("/student/profile");
    } else if (window.location.pathname === "/user") {
      return this.props.history.replace("/flive");
    } else if (
      allowedRoutes.some((route) => route === window.location.pathname)
    ) {
      this.props.history.push("/flive");
    } else if (window.location.pathname === "/assessment") {
      this.props.history.push("/assessment");
    }else if (window.location.pathname == "/guided-path/" && window.location.search != '') {
      return this.props.history.push("/guided-path");
    } else {
      return window.location.reload();
    }
    return window.location.reload();
  };

  logoutUser = async () => {
   const sessionObj = {
     started_at: this.props?.startSessionTime
       ? new Date(this.props.startSessionTime)
       : new Date(JSON.parse(localStorage.getItem("sessionStart"))),
     ended_at: new Date(),
   };
    await this.props.callSessionSet(sessionObj);
    // Mixpanel.track('Logout Event')
    this.logAnalytics("Logout");
    Analytics.onlogOut({
      start: sessionObj.started_at,
      end: sessionObj.ended_at,
    });
    LocalStorage.destroy.all();
    store.dispatch({ type: "RESET_STATE" });
    this.props.history.push("/onboarding");
  };

  updateUser = async () => {
    await getAndSetChild(store.dispatch, {}, null, true);
  };

  componentDidMount() {

    var email = "";

    var referrer = document.referrer;

    email = LocalStorage.fetch.parentData()?.email;

    if (email === "" || email === undefined)
      email = LocalStorage.fetch.parentData()?.contact_no + "@freadom.com";

    if (email === "" || email === undefined) {
      email = LocalStorage.fetch.childData()?.email;
    }

    email = LocalStorage.fetch.parentData()?.email;

    if (email === "" || email === undefined)
      email = LocalStorage.fetch.parentData()?.contact_no + "@freadom.com";

    if (email === "" || email === undefined) {
      email = LocalStorage.fetch.childData()?.email;
    }

    var userData = {
      email: email,
      sub: LocalStorage.fetch.parentData()?.id,
      name: LocalStorage.fetch.parentData()?.name,
      phone: LocalStorage.fetch.parentData()?.contact_no,
      iat: Math.round(new Date().getTime() / 1000), // token issue time
      exp: Math.round(new Date().getTime() / 1000) + 604800, // token expiration time
    };

    let new_value = {
      title: "Community",
      url: [
        "https://freadom.tribe.so/auth/sso?ssoToken=" +
          jwt.sign(userData, privateKey, { algorithm: "HS256" }) +
          "&redirect=/",
      ],
      icon: CommunityIcon,
    };

    if (referrer === "https://freadom.tribe.so/") {
      window.location.assign(new_value.url);
    }

    this.setState({
      defaultChildData: LocalStorage.fetch.defaultChild(),
    });

    if(LocalStorage.fetch.defaultChild()?.language==='Hindi'){
      this.state.navbarLinks.splice(3,1)
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    //if(this.props.location.pathname != prevProps.location.pathname)alert('changed');
    if (prevState.switchingChild !== this.state.switchingChild) {
      this.setState({
        defaultChildData: LocalStorage.fetch.defaultChild(),
      });
    }
    if (this.props.levels && this.props.levels.length === 0) {
      this.props.getAllLevels();
    }
  }

  hasKeySetTo(myArray, value) {
    let res = false;
    for (var i = 0; i < myArray.length; i++) {
      if (myArray[i].hasOwnProperty("key") && myArray[i]["key"] === value)
        res = true;
    }
    return res;
  }

  handleButtonPress() {
    this.cleared = false;
    this.buttonPressTimer = setTimeout(() => {
      this.cleared = true;
      this.setState({
        showSearch: !this.state.showSearch,
        shwContentSrch:
          this.state.shwContentSrch === true
            ? false
            : this.state.shwContentSrch,
        resultList: [],
        filterData: level,
        searchString: null,
      });
    }, 1500);
    if (this.state.showSearch) {
      this.logAnalytics("Search");
    }
  }

  logAnalytics = (to, from = "") => {
    this.scrollToTo();
    if (to !== "Community") {
      from = getSourcePage(from);
      to = getSourcePage(to);
      Analytics.logNavigation({ from: from, to: to });
    } else {
      communityAnalytics();
    }
  };
  handleButtonRelease() {
    clearTimeout(this.buttonPressTimer);
    //FREAD-1235
    if (!this.cleared) this.props.history.push("/");
    //FREAD-1235
  }
  //FREAD-1235
  handleonMouseLeaveRelease() {
    clearTimeout(this.buttonPressTimer);
  }
  //FREAD-1235
  searchAnything = (evt) => {
    this.setState({ searchString: evt.state.search });
    let searchQuery = "";
    if (evt.state.search) {
      searchQuery = `query=${evt.state.search}`;
    }
    if (this.state.selectedLevel) {
      searchQuery = `${searchQuery}&grade_level=${this.state.selectedLevel}`;
    }
    this.props.getGlobalSearch(searchQuery);
    if (this.state.selectedFilter) {
      //FREAD-1168
      searchQuery = `${searchQuery}&index_type=${this.state.selectedFilter.value}`;
    }
    this.props.getGlobalSearch(searchQuery);
  };

  selectOption = async (value) => {
    if (value && value.length > 0) {
      if (value[0]._index === "activity") {
        this.props.history.push(`/activity/${value[0]._source.id}`);
      } else if (value[0]._index === "story") {
        this.props.history.push(`/story/${value[0]._source.id}`);
      } else if (value[0]._index === "quiz") {
        this.props.history.push(`/quiz/${value[0]._source.id}`);
      } else if (value[0]._index === "news_fread") {
        this.props.history.push(`/news/${value[0]._source.id}`);
      }
    }
  };

  handleActivityOpenModal = async (
    id,
    isActivityPack = false,
    gradeId = ""
  ) => {
    let res;
    let result;
    if (isActivityPack) {
      this.state.isActivityPack = true;
      let payload = {
        id: id,
        gradeId: gradeId,
      };
      res = await this.props.getActivityPackDetailsRequest(payload);

      this.setState({
        activeActivityPackData: res,
        section: EVENT.SOURCE_PAGE_ACTIVITIES,
      });
    } else {

      res = await this.props.getActivityDetailRequest(id);
      this.setState({ activeActivityData: res, selectedResult: res });
      result = await this.props.getActivityQuestionsRequest(id);
      this.setState({
        activeActivityQuestionsList: result,
        section: EVENT.SOURCE_INDIVIDUAL_ACTIVITY,
      });
    }
    if (res && Object.keys(res).length > 0) {
    } else {
      alert("No data found");
    }
    this.setState({ isActivityModalOpen: true });
    if (
      this.state.activeActivityPackData &&
      this.state.activeActivityPackData.status === "locked"
    ) {
      this.setState({ section: EVENT.SOURCE_LOCKED_ACTIVITY_PACKS });
    } else if (
      this.state.activeActivityPackData &&
      this.state.activeActivityPackData.status === "unlocked"
    ) {
      this.setState({ section: EVENT.SOURCE_UNLOCKED_ACTIVITY_PACKS });
    }
  };
  closeModal = () => {
    this.setState({
      isModalOpen: false,
      isActivityModalOpen: false,
      openParentProfile: false,
      openAddProfile: false,
    });
    this.setState({ selectedResult: null });
    // Analytics.onLogin();
  };

  selectLevel = (data) => {
    this.setState({ selectedLevel: data });
    let searchQuery = "";
    if (this.state.searchString) {
      searchQuery = `query=${this.state.searchString}`;
      if (data) {
        searchQuery = `${searchQuery}&grade_level=${data}`;
      }
      if (this.state.selectedFilter) {
        searchQuery = `${searchQuery}&index_type=${this.state.selectedFilter.value}`;
      }
      this.props.getGlobalSearch(searchQuery);
    }
  };
  selectFilter = (data) => {
    if (data && this.state.filterData.length > 0) {
      let searchQuery = "";
      const filterOption = this.state.filterData.map((option) => ({
        name: option.name,
        selected: data.name === option.name ? true : false,
        value: option.value,
      }));
      this.setState({ filterData: filterOption, selectedFilter: data });
      if (this.state.searchString) {
        searchQuery = `query=${this.state.searchString}`;
      }
      if (this.state.selectedLevel) {
        searchQuery = `${searchQuery}&grade_level=${this.state.selectedLevel}`;
      }
      if (data) {
        searchQuery = `${searchQuery}&index_type=${data.value}`;
      }
      this.props.getGlobalSearch(searchQuery);
    }
  };
  clearSearch = () => {
    this.setState({ resultList: [] });
  };

  getLink(linkObj) {
    return linkObj.url[0];
  }
  goToCommunity = () => {
    var userData = {
      email: LocalStorage.fetch.parentData()?.email,
      sub: LocalStorage.fetch.parentData()?.id,
      name: LocalStorage.fetch.parentData()?.name,
      phone: LocalStorage.fetch.parentData()?.contact_no,
      iat: Math.round(new Date().getTime() / 1000), // token issue time
      exp: Math.round(new Date().getTime() / 1000) + 604800, // token expiration time
    };
    let url =
      "https://freadom.tribe.so/auth/sso?ssoToken=" +
      jwt.sign(userData, privateKey, { algorithm: "HS256" }) +
      "&redirect=/";
    window.open(url, "_self");
  };
  checkLink = (link) => {
    if (
      link.title === "Community" &&
      !LocalStorage.fetch.parentData()?.is_email_verified
    ) {
      this.setState({ openParentProfile: true, emailCheckFor: link.title });
    }
    this.logAnalytics(link.title);
  };

  navLink = (link, index, external) => {
    return external ? (
      <a
        href={
          LocalStorage.fetch.parentData()?.is_email_verified &&
          link.title === "Community" &&
          link.url[0]
        }
        onClick={() => this.checkLink(link)}
        target="_self"
        className={
          "display-menu" +
          (link.url?.includes(this.props.location.pathname)
            ? " active active-border"
            : "")
        }
      >
        <div
          className={
            "navbar-link" +
            (link.url?.includes(this.props.location.pathname) ? " active" : "")
          }
        >
          <img
            src={link.icon}
            className="navbar-links-icon"
            alt="Navbar icon"
          />
        </div>
        <div> {link.title}</div>
      </a>
    ) : (
      <Link
        to={this.getLink(link)}
        onClick={() => {
          this.logAnalytics(link.title, this.props.location.pathname);
          this.state.shwContentSrch &&
            this.setState({ shwContentSrch: !this.state.shwContentSrch });
        }}
        className={
          "display-menu" +
          (link.url?.includes(this.props.location.pathname)
            ? " active active-border"
            : "")
        }
        key={index}
      >
        <div
          className={
            "navbar-link" +
            (link.url?.includes(this.props.location.pathname) ? " active" : "")
          }
        >
          <img
            src={link.icon}
            className={`navbar-links-icon ${
              link.title.toLowerCase() === "news"
                ? link.title.toLowerCase()
                : ""
            }`}
            alt="Navbar icon"
          />
        </div>
        <div> {link.title}</div>
      </Link>
    );
  };

  scrollToTo = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  bookmark = (id) => {
    if (this.props.location.pathname !== "/story") {
      this.props.bookmarkFeed({
        header: this.state.storySection.header,
        id: id,
      });
    } else {
      this.props.bookmarkStory({ id: id });
    }
    this.setState({ activeStoryData: {} });
  };
  render() {
    let { defaultChildData, shwContentSrch } = this.state;
    const childList = LocalStorage.fetch.childData();
    let showSearchInput = this.state.selectedLevel ? false : true;
    const levelList = this.props.levels;
    const { navbarLinks, navbarLinks2, navbarLinks3 } = this.state;

    return [
      <div>
        <div
          className={[
            "navbar-container",
            this.props.showNavbar ? "" : "hidden",
          ].join(" ")}
        >
          <span
            className="hamburger-icon"
            onClick={() => this.setState({ navbarOpened: true })}
          >
            &#9776;
          </span>
          <div
            style={{ display: "flex", width: "100%" }}
            className="navbar-left"
          >
            {
              <div
                onTouchStart={this.handleButtonPress}
                onTouchEnd={this.handleButtonRelease}
                onMouseDown={this.handleButtonPress}
                onMouseUp={this.handleButtonRelease}
                onMouseLeave={this.handleonMouseLeaveRelease}
                className="navbar_logo_container"
              >
                <img
                  src={Freadom}
                  onClick={this.scrollToTo}
                  className="logo"
                  alt="logo"
                />
                <img
                  src={MadhubanLogo}
                  onClick={this.scrollToTo}
                  className="logo"
                  alt="logo"
                />
              </div>
            }
            <div className="navbar-links-container flex"></div>
            <div className="navbar-links-container flex"></div>

            {!navbarLinks2[0]?.url?.includes(this.props.location?.pathname) && //this.props.location.pathname != '/user'
              this.props.location.pathname !== "/quiz" && (
                <div className="navbar-links-container flex group-link">

                  <a
                    style={{ textDecoration: "none", marginRight: "5px" }}
                    href="/"
                    onClick={(e) => {
                      e.preventDefault();
                      this.setState({
                        showSearchModal: true,
                      });
                    }}
                    className={
                      "display-menu " +
                      (this.state.showSearchModal ? " active active-border" : "")
                    }
                  >
                    <div
                      className={
                        "navbar-link" + (this.state.showSearchModal ? " active" : "")
                      }
                    >
                      <img
                        src={SearchIcon}
                        className="navbar-links-icon"
                        alt="Search icon"
                      />
                    </div>
                    <div>Search</div>
                  </a>
                </div>
              )}
            <div className="navbar-links-container flex group-link">

              {navbarLinks.map((link, index) =>
                this.navLink(link, index, false)
              )}

            </div>

            <div className="navbar-links-container flex group-link-3">
              {navbarLinks3.map(
                (link, index) => this.navLink(link, index, true)
              )}
            </div>
          </div>
          {/* //FREAD-1337 */}
          {!navbarLinks2[0]?.url?.includes(this.props.location?.pathname) && //this.props.location.pathname != '/user'
            this.props.location.pathname !== "/quiz" && (
              <div className="search-mobile">
                {/* //FREAD-1337 */}
                <Icon
                  type="search"
                  onClick={(e) => {
                    e.preventDefault();
                    this.setState({
                      showSearchModal: true,
                    });
                  }}
                />
              </div>
            )}
          {defaultChildData &&
            childList &&
            Object.keys(defaultChildData)?.length > 0 &&
            !this.props?.hideForCMFluency && (
              <div className="user-data">
                <Dropdown
                  overlay={
                    <div className="child-list-container">
                      {childList && defaultChildData?.school_code
                        ? childList?.list?.map((c) => (
                            <div
                              className="child-list-item"
                              style={{
                                border:
                                  c.id === defaultChildData.id
                                    ? "1px solid #e31e24"
                                    : "none",
                                background:
                                  c.id === defaultChildData.id
                                    ? "#ffeff8"
                                    : "none",
                              }}
                              onClick={() => this.handleSwitchChild(c.id)}
                              key={c.id}
                            >
                              <div className="child-name">
                                {c.image ? (
                                  <img
                                    src={c.image}
                                    alt="user"
                                    className="child-image"
                                  />
                                ) : (
                                  <Avatar className="child-image">
                                    {c.name[0]}
                                  </Avatar>
                                )}
                                <span id={c.id} className="name-trunc">
                                  {`${c.name} (${c.language[0]})`}
                                  {c.grade && c.grade.name ? (
                                    <span className="name-trunc grade-text">
                                      {`${c.grade.name}${
                                        c?.section && !c?.is_teacher
                                          ? ` - ${c?.section?.name}`
                                          : ""
                                      }`}
                                    </span>
                                  ) : null}
                                  {c?.school}
                                </span>
                              </div>

                            </div>
                          ))
                        : childList?.list
                            ?.filter((child) => child.language === "English")
                            ?.map((c) => (
                              <div
                                className="child-list-item"
                                style={{
                                  border:
                                    c.id === defaultChildData.id
                                      ? "1px solid #e31e24"
                                      : "none",
                                  background:
                                    c.id === defaultChildData.id
                                      ? "#ffeff8"
                                      : "none",
                                }}
                                onClick={() => this.handleSwitchChild(c.id)}
                                key={c.id}
                              >
                                <div className="child-name">
                                  {c.image ? (
                                    <img
                                      src={c.image}
                                      alt="user"
                                      className="child-image"
                                    />
                                  ) : (
                                    <Avatar className="child-image">
                                      {c.name[0]}
                                    </Avatar>
                                  )}
                                  <span id={c.id} className="name-trunc">
                                    {`${c.name} (${c.language[0]})`}
                                    {c.grade && c.grade.name ? (
                                      <span className="name-trunc grade-text">
                                        {`${c.grade.name}${
                                          c?.section && !c?.is_teacher
                                            ? ` - ${c?.section?.name}`
                                            : ""
                                        }`}
                                      </span>
                                    ) : null}
                                    {c?.school}
                                  </span>
                                </div>

                              </div>
                            ))}

                      <hr className="no-margin"></hr>
                      <div
                        className="child-list-item"
                        onClick={() =>
                          this.setState({
                            openParentProfile: true,
                            emailCheckFor: "profileSetting",
                          })
                        }
                      >
                        <div style={{ display: "flex" }}>
                          {" "}
                          <div className="icon-container">
                            <img
                              src={SettingsIcon}
                              alt="user"
                              className="menu-image"
                            />
                          </div>
                          {this.state.parentData
                            ? this.state.parentData.is_teacher
                              ? "Teacher Profile"
                              : "Parent Profile"
                            : null}
                        </div>
                      </div>

                      <div
                        className="child-list-item"
                        onClick={this.logoutUser}
                      >
                        <div style={{ display: "flex" }}>
                          {" "}
                          <div className="icon-container">
                            <img
                              src={LogoutIcon}
                              alt="user"
                              className="menu-image"
                            />
                          </div>
                          Logout
                        </div>
                      </div>
                    </div>
                  }
                  trigger={["click"]}
                  overlayClassName="navbar-user-dropdown-menu"
                >
                  <div className="user-pill">

                    {defaultChildData.image ? (
                      <img
                        className="display-picture"
                        src={defaultChildData.image}
                        alt="user"
                      />
                    ) : (
                      <Avatar className="display-picture">
                        {defaultChildData.name && defaultChildData.name[0]}
                      </Avatar>
                    )}

                  </div>
                </Dropdown>

              </div>
            )}

          <div
            className="navbar-overlay"
            style={{ height: this.state.navbarOpened ? "100%" : "0" }}
          >
            <p
              className="close-btn"
              onClick={() => this.setState({ navbarOpened: false })}
            >
              &times;
            </p>
            <div className="overlay-content">
              <div className="navbar-links-container flex">
                {navbarLinks.map((link, index) => (
                  <Link
                    to={link.url[0]}
                    onClick={() => {
                      this.logAnalytics(
                        link.title,
                        this.props.location.pathname
                      );
                    }}
                    key={index}
                  >
                    <div className="navbar-link">{link.title}</div>
                  </Link>
                ))}
                {navbarLinks2.map((link, index) => (
                  <Link
                    to={link.url[0]}
                    onClick={() => {
                      this.logAnalytics(
                        link.title,
                        this.props.location.pathname
                      );
                    }}
                    key={index}
                  >
                    <div className="navbar-link">{link.title}</div>
                  </Link>
                ))}
                {navbarLinks3.map((link, index) => (
                  <a
                    href={
                      LocalStorage.fetch.parentData()?.is_email_verified
                        ? link.url[0]
                        : "#"
                    }
                    onClick={() => this.checkLink(link)}
                    target="_self"
                  >
                    <div className="navbar-link">{link.title}</div>
                  </a>
                ))}

              </div>
            </div>
          </div>
        </div>
      </div>,
      <Modal
        title="Add Profile"
        centered
        visible={this.state.openAddProfile}
        onCancel={() => this.closeModal()}
        footer={null}
        key={2}
        destroyOnClose
      >
        <AddProfile close={this.closeModal} data={this.state.parentData} />
      </Modal>,
      <Modal
        title={
          this.state.parentData
            ? this.state.parentData.is_teacher
              ? "Teacher Profile"
              : "Parent Profile"
            : null
        }
        centered
        visible={this.state.openParentProfile}
        onCancel={() => this.closeModal()}
        footer={null}
        key={1}
        destroyOnClose
      >
        <ParentProfile
          fromLink={this.state.emailCheckFor}
          close={this.closeModal}
          goToCommunity={this.goToCommunity}
        />
      </Modal>,
      <Modal
        title="Activity Summary"
        centered
        className="activity-modal"
        visible={this.state.isActivityModalOpen}
        onCancel={() => this.closeModal()}
        footer={null}
        key={1}
        destroyOnClose
      >
        <ActivityModal
          section={this.state.section}
          source={EVENT.SOURCE_FEED_PAGE}
          data={
            this.state.isActivityPack
              ? this.state.activeActivityPackData
              : this.state.isReadingChallenge
              ? this.state.activeReadingChallengeData
              : this.state.activeActivityData
          }
          questions={
            this.state.isActivityPack
              ? []
              : this.state.isReadingChallenge
              ? this.state.activeReadingChallengeQuestionsList
              : this.state.activeActivityQuestionsList
          }
          openSuccessModal={(data) => this.openSuccessModal(data)}
          close={(id, isActivityPack, gradeId) =>
            this.closeModal(id, isActivityPack, gradeId)
          }
          isActivityPack={this.state.isActivityPack}
          isReadingChallenge={this.state.isReadingChallenge}
        />
      </Modal>,
      <Modal
        title="Search Modal"
        centered
        className="activity-modal"
        visible={this.state.showSearchModal}
        onCancel={() => this.closeModal()}
        footer={null}
        key={1}
        destroyOnClose
      >
        <SearchModal
          onClose={() => this.setState({ showSearchModal: false })}
          dataClick={this.state.dataClick}
          setDataClick={(value) => this.setState({ dataClick: value })}
        />
      </Modal>,
    ];
  }
}

export default connect(
  ({ user, teacher, story, activity, appConfig, student, home }) => ({
    ...user,
    ...appConfig,
    ...teacher,
    ...story,
    ...activity,
    ...home,
    student,
  }),
  (dispatch) =>
    bindActionCreators(
      {
        ...userActions,
        ...homeActions,
        ...teacherActions,
        ...storyActions,
        ...activityActions,
      },
      dispatch
    )
)(withRouter(Navbar));
